import { Interceptors } from './contracts/http-client.contract';
import { Oauth2Controller } from './contracts/Oauth2';

enum LocalStorageKey {
    accessToken = 'access_token',
    refreshToken = 'refresh_token',
    userInfo = 'user_info'
}

const interceptors: Interceptors = {
    request: [
        {
            onFulfilled: (config) => {
                return config;
            },
            onRejected: (error) => {
                return Promise.reject(error);
            }
        }
    ],
    response: [
        {
            onFulfilled: (res) => {
                return res;
            },
            onRejected: async (err) => {
                const originalConfig = err.config;

                const responseStatus = err?.response?.status ?? undefined;

                switch (responseStatus) {
                    case 401:
                        if (originalConfig.url === '/refresh-token') {
                            // Access and refresh tokens have expired
                            localStorage.removeItem(
                                LocalStorageKey.refreshToken
                            );

                            if (process.env.NODE_ENV !== 'development') {
                                window.location.replace('/login');
                            }

                            return Promise.reject(err);
                        } else if (!originalConfig._retry) {
                            // Access token has expired
                            originalConfig._retry = true;

                            localStorage.removeItem(
                                LocalStorageKey.accessToken
                            );

                            const refreshController = new Oauth2Controller({
                                interceptors
                            });

                            return refreshController
                                .oauthRefreshToken()
                                .then(({ data, status }) => {
                                    if (status === 200) {
                                        return originalConfig;
                                    }
                                })
                                .catch((err) => {
                                    window.location.replace('/login');
                                    return Promise.reject(err);
                                });
                        }
                        break;
                    case 503:
                        if (window.location.pathname !== '/maintenance') {
                            window.location.replace('/maintenance');
                            return Promise.reject(err);
                        }
                        break;
                    default:
                        console.error(
                            `An unhandled API error occurred: [${responseStatus}] ${originalConfig.url}`
                        );
                }

                return Promise.reject(err);
            }
        }
    ]
};

export default interceptors;
