import { AxiosResponse } from 'axios';
import { MutationOptions, useMutation, useQueryClient } from 'react-query';

import { AppController } from 'core/api/contracts/app.contract';
import interceptors from 'core/api/interceptors';
import securityWorker from 'core/api/securityWorker';

type DeleteNotificationParams = { query: { uuid: string } };

function useDeleteNotification<TError = unknown, TContext = unknown>(
    options?: Omit<
        MutationOptions<
            AxiosResponse<void>,
            ReactQueryError<TError>,
            DeleteNotificationParams,
            TContext
        >,
        'mutationFn'
    >
) {
    const queryClient = useQueryClient();

    return useMutation(
        [],
        async ({ query }: DeleteNotificationParams) => {
            const controller = new AppController({
                interceptors,
                securityWorker
            });

            return controller.deleteNotification(query.uuid);
        },
        {
            ...options,
            onSettled: (data, error, variables, context) => {
                options?.onSettled?.(data, error, variables, context);

                queryClient.invalidateQueries({ queryKey: ['notifications'] });
            }
        }
    );
}

export default useDeleteNotification;
export type { DeleteNotificationParams };
