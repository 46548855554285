// import { CoreController } from 'core/api/autogenerated/Core';
// import { UserData } from 'ui/admin/user-management/user-details-form';
// import { AppController } from 'core/api/autogenerated/App';
// import { UserNotificationPreferences } from 'core/api/autogenerated/data-contracts';
import { AppController } from 'core/api/contracts/app.contract';
import { UserNotificationPreferences } from 'core/api/contracts/contract.types';
import { useMutation, useQueryClient } from 'react-query';

export type UserNotificationData = {
    data: UserNotificationPreferences;
};

// TODO: use common mutation.
function useUpdateNotificationPreferences() {
    const queryClient = useQueryClient();
    return useMutation(
        ({ data }: UserNotificationData) => {
            const controller = new AppController({});
            return controller.putNotificationPreferences(data);
        },
        {
            onError: (_error, _variables, context) => {
                // show an error message or a toast or something
            },
            onMutate: ({ data }) => {
                return { data };
            },
            onSettled: () => {
                queryClient.refetchQueries('user-data');
            }
        }
    );
}

export default useUpdateNotificationPreferences;
