import React, {
    Dispatch,
    SetStateAction,
    createContext,
    useContext
} from 'react';
import useNotifications from './useNotifications';

interface NotificationsContextValue {
    handleNotificationsToggle;
    isError: boolean;
    isFetching: boolean;
    isLoading: boolean;
    notificationData;
    openNotificationsWidget;
    remoteTrigger;
    selectedNotificationInContext;
    setNotificationData;
    setOpenNotificationsWidget;
    setRemoteTrigger;
    setSelectedNotificationInContext: Dispatch<
        SetStateAction<object | Notification>
    >;
}

export const NotificationsContext =
    createContext<NotificationsContextValue | null>(null);

const NotificationsProvider: React.FC<React.ReactNode> = ({ children }) => {
    const {
        handleNotificationsToggle,
        isError,
        isFetching,
        isLoading,
        notificationData,
        openNotificationsWidget,
        remoteTrigger,
        selectedNotificationInContext,
        setNotificationData,
        setOpenNotificationsWidget,
        setRemoteTrigger,
        setSelectedNotificationInContext
    } = useNotifications();

    return (
        <NotificationsContext.Provider
            value={{
                handleNotificationsToggle,
                isError,
                isFetching,
                isLoading,
                notificationData,
                openNotificationsWidget,
                remoteTrigger,
                selectedNotificationInContext,
                setNotificationData,
                setOpenNotificationsWidget,
                setRemoteTrigger,
                setSelectedNotificationInContext
            }}
        >
            {children}
        </NotificationsContext.Provider>
    );
};

export const useNotificationsContext = () => {
    const notificationsContext = useContext(NotificationsContext);

    if (!notificationsContext)
        throw new Error('You need to use context inside of a provider');

    return notificationsContext;
};

export { NotificationsProvider };
export type { NotificationsContextValue };
