import { useCallback } from 'react';
import { useQuery } from 'react-query';

import { CoreController } from 'core/api/contracts/core';
import interceptors from 'core/api/interceptors';
import securityWorker from 'core/api/securityWorker';

function useDayRates() {
    const request = useCallback(async () => {
        const controller = new CoreController({
            interceptors,
            securityWorker
        });

        // We don't want to catch any errors here since it will broke react query "error" status
        const response = await controller.getDayRates();

        return response.data;
    }, []);

    return useQuery(['day-rates', 'list'], request);
}

export default useDayRates;
