import { MouseEventHandler } from 'react';
import { RenderAccountOptions, accountOptions } from 'ui/account/account';
import { Typography } from '@ncc-frontend/core';
import { faBell } from '@fortawesome/pro-regular-svg-icons';
import { useLocation } from 'react-router-dom';
import { useNotificationsContext } from 'ui/notifications/notifications-context';
import { useTranslation } from 'react-i18next';
import AppHeader, { AppHeaderProps } from 'app-layout/app-header';
import Avatar from 'components/avatar/avatar';
import Dropdown from 'components/dropdown/dropdown';
import FontIcon from 'components/icon/icon';
import NotificationsContent from 'ui/notifications/notifications-content';
import Popout from 'components/popout/popout';
import classNames from 'classnames';
import modalViewConfiguration from 'pages/notifications/view-configs/modal-view-configuration';

interface MainHeaderProps extends AppHeaderProps {
    collapsed?: boolean;
    onToggleClick: MouseEventHandler<HTMLDivElement>;
}

function MainHeader({
    className,
    collapsed,
    onToggleClick,
    ...restProps
}: MainHeaderProps) {
    const { t } = useTranslation();
    const location = useLocation();
    const { notificationData } = useNotificationsContext();

    const notificationLength = (): number | undefined => {
        if (notificationData) {
            const result = notificationData.filter((n) => {
                return !n.is_read;
            });
            return result.length;
        }
    };

    const cssClasses = classNames(
        className,
        'transition-all border-b-[1px] flex',
        {
            'left-[240px]': !collapsed,
            'left-[72px]': collapsed
        }
    );

    return (
        <AppHeader {...restProps} className={cssClasses}>
            <div className="flex-inline flex items-center w-full px-6">
                <Typography.Header
                    level={3}
                    tailwindStyle={{
                        border: 'border-r pr-[24px] dark:text-white'
                    }}
                >
                    {collapsed && t('application.app-title')}
                </Typography.Header>
                <span className="ml-ncc-margin-24 px-2 py-1 text-[11px] rounded-md bg-ncc-grey-20 text-grey-100 block w-fit">
                    Version: {process.env.REACT_APP_VERSION}
                </span>

                <div className="ml-auto flex items-center gap-4">
                    {!location.pathname.includes('notifications') && (
                        <Popout
                            notificationLength={notificationLength()}
                            content={
                                <NotificationsContent
                                    configuration={modalViewConfiguration}
                                ></NotificationsContent>
                            }
                            trigger={<FontIcon icon={faBell}></FontIcon>}
                        ></Popout>
                    )}

                    <Dropdown
                        trigger={<Avatar></Avatar>}
                        options={accountOptions}
                        renderOptions={RenderAccountOptions}
                    ></Dropdown>
                </div>
            </div>
        </AppHeader>
    );
}

export default MainHeader;
export type { MainHeaderProps };
