import { AppController } from 'core/api/contracts/app.contract';
import { useQuery } from 'react-query';
import interceptors from 'core/api/interceptors';
import securityWorker from 'core/api/securityWorker';

function useGetReporterProducts(query?) {
    return useQuery(
        ['products', 'list', { query }],

        async () => {
            console.log('Query:', query);
            const controller = new AppController({
                interceptors,
                securityWorker
            });

            // We don't want to catch any errors here since it will broke react query "error" status
            const response = await controller.appReporterDashboard(query);
            return response.data;
        }
    );
}

export default useGetReporterProducts;
