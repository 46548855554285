export interface CheckboxProp {
    id: string;
    name?: string;
    value: boolean;
}

export interface ICheckboxProp {
    changeHandler?;
    option: CheckboxProp;
}

const CheckboxGroup = ({ changeHandler, option }: ICheckboxProp) => {
    return (
        <div className="ml-4 mr-4">
            <label>
                {option.name && <h4>{option.name}</h4>}
                <input
                    type="checkbox"
                    id={option.id}
                    checked={option.value}
                    onChange={changeHandler}
                />
            </label>
        </div>
    );
};

export default CheckboxGroup;
