import { AdminUsersProvider } from 'ui/admin/user-management/admin-users-context';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { useLocalStorage } from '@ncc-frontend/core';
import AdminHolderPage from '../pages/admin/admin-holder-page';
import AdminUserDetailsPage from 'ui/admin/user-management/admin-user-details-page';
import AdminUsersPage from '../pages/admin/user-management/admin-users-page';
import AllocationsPage from '../pages/allocations/allocations-page';
import CoreLayout from './core-layout';
import CostLevelsPage from '../pages/admin/cost-levels/cost-levels-page';
import LoadingPage from '../pages/loading/loading-page';
import LoginPage from '../pages/login/login-page';
import MainDashboardPage from '../pages/dashboard/main-dashboard-page';

import './app-layout.scss';
import { AllocationsProvider } from 'ui/allocations/allocations-context';
import { DashboardProvider } from 'ui/dashboard/dashboard-context';
import { DataTableProvider } from 'components/data-table/data-table-context';
import { ErrorPanel } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NotificationsProvider } from 'ui/notifications/notifications-context';
import { ProductReportsProvider } from 'ui/admin/product-reports/product-reports-context';
import { ProductsProvider } from 'ui/admin/products/products-context';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import ActivityGroupsPage from '../pages/admin/activity-groups/activity-groups-page';
import AllocationsPersonalContainer from 'ui/allocations/allocations-personal-container';
import Button from 'components/button/button';
import ContextWrapper from 'components/calendar/context/context-wrapper';
import NotificationView from 'pages/notifications/notification-view';
import NotificationsContainer from 'pages/notifications/notifications-container';
import NotificationsManagmentPage from 'pages/admin/notifications-management/notifications-management-page';
import NotificationsPage from 'pages/notifications/notifications-page';
import ProductReportsPage from '../pages/admin/product-reports/product-reports-page';
import ProductsPage from '../pages/admin/products/product-page';
import ProtectedRoute from 'common/protected-route';
import SettingsHolderPage from 'pages/settings/settings-holder-page';
import TeamsPage from '../pages/admin/teams-page/teams-page';
import useAuth from 'authLib/use-auth';

function AppLayout() {
    const { status } = useAuth();
    const goTo = useNavigate();

    const [collapsed, setCollapsed] = useLocalStorage<boolean>(
        'sider-collapsed',
        false
    );

    const onToggleClick = useCallback(() => {
        setCollapsed(!collapsed);
    }, [collapsed, setCollapsed]);

    return (
        <div className="flex w-screen h-screen">
            <Routes>
                {status === 'not-authorized' && (
                    <>
                        <Route path="/" element={<LoginPage />} />
                        <Route path="login" element={<LoginPage />} />
                    </>
                )}
                {status === 'authorizing' && (
                    <Route path="oauth2/auth" element={<LoadingPage />} />
                )}
                {status === 'authorized'} && (
                <Route
                    element={
                        <ProtectedRoute>
                            <CoreLayout
                                collapsed={collapsed}
                                onToggleClick={onToggleClick}
                            />
                        </ProtectedRoute>
                    }
                >
                    <Route
                        path="/dashboard"
                        element={
                            <AllocationsProvider>
                                <DashboardProvider>
                                    <DataTableProvider>
                                        <MainDashboardPage
                                            collapsed={collapsed}
                                        />
                                    </DataTableProvider>
                                </DashboardProvider>
                            </AllocationsProvider>
                        }
                    />
                    <Route
                        path="allocations"
                        element={
                            <AllocationsProvider>
                                <AllocationsPage collapsed={collapsed} />
                            </AllocationsProvider>
                        }
                    >
                        <Route
                            path="personal"
                            element={
                                <AllocationsProvider>
                                    <AllocationsPersonalContainer />
                                </AllocationsProvider>
                            }
                        />
                        <Route
                            path="team"
                            element={
                                <AllocationsProvider>
                                    <DataTableProvider>
                                        <TeamsPage></TeamsPage>
                                    </DataTableProvider>
                                </AllocationsProvider>
                            }
                        />
                    </Route>
                    <Route
                        path="product-reports"
                        element={
                            <ProductReportsProvider>
                                <ProductReportsPage collapsed={collapsed} />
                            </ProductReportsProvider>
                        }
                    />
                    <Route
                        path="notifications"
                        element={
                            <NotificationsProvider>
                                <NotificationsContainer
                                    collapsed={collapsed}
                                ></NotificationsContainer>
                            </NotificationsProvider>
                        }
                    >
                        <Route
                            path=":uuid"
                            element={<NotificationView></NotificationView>}
                        />
                    </Route>
                    <Route
                        path="admin"
                        element={<AdminHolderPage collapsed={collapsed} />}
                    >
                        <Route
                            path="cost-levels"
                            element={<CostLevelsPage collapsed={collapsed} />}
                        />
                        <Route
                            path="products"
                            element={
                                <ProductsProvider>
                                    <ProductsPage
                                        collapsed={collapsed}
                                    ></ProductsPage>
                                </ProductsProvider>
                            }
                        />
                        <Route
                            path="users"
                            element={
                                <AdminUsersProvider>
                                    <AdminUsersPage />
                                </AdminUsersProvider>
                            }
                        />
                        <Route
                            path="users/:id"
                            element={
                                <AdminUsersProvider>
                                    <AdminUserDetailsPage />
                                </AdminUsersProvider>
                            }
                        />
                        <Route
                            path="activity-groups"
                            element={
                                <ActivityGroupsPage collapsed={collapsed} />
                            }
                        />
                        <Route
                            path="notifications-management"
                            element={
                                <ContextWrapper>
                                    <NotificationsManagmentPage
                                        collapsed={collapsed}
                                    />
                                </ContextWrapper>
                            }
                        />
                    </Route>
                    )
                    <Route
                        path="settings"
                        element={<SettingsHolderPage collapsed={collapsed} />}
                    >
                        <Route
                            path="notifications"
                            element={
                                <NotificationsPage collapsed={collapsed} />
                            }
                        />
                    </Route>
                    <Route
                        path="*"
                        element={
                            <div className="h-full w-full px-escode-body-padding-x py-escode-body-padding-y">
                                <ErrorPanel code={404}>
                                    <Button
                                        icon={
                                            <FontAwesomeIcon
                                                icon={faArrowLeft}
                                            />
                                        }
                                        onClick={() => goTo('/')}
                                    >
                                        Back to login
                                    </Button>
                                </ErrorPanel>
                            </div>
                        }
                    />
                </Route>
                )
            </Routes>
        </div>
    );
}

export default AppLayout;
