import { useCallback } from 'react';
import { useQuery } from 'react-query';

import { CoreController } from 'core/api/contracts/core';
import interceptors from 'core/api/interceptors';
import securityWorker from 'core/api/securityWorker';

function useActivityGroups(query?: { name?: string }) {
    const request = useCallback(async () => {
        const controller = new CoreController({
            interceptors,
            securityWorker
        });
        const response = await controller.getActivityGroups(query);
        return response.data;
    }, [query]);

    return useQuery(['activity-groups', query], request);
}

export default useActivityGroups;
