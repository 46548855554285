import { IConfigTabs, INotificationConfiguration } from './config.types';
import {
    faArchive,
    faCog,
    faExclamationTriangle,
    faHandPointUp,
    faSensorAlert
} from '@fortawesome/pro-regular-svg-icons';
import FontIcon from 'components/icon/icon';

const tabs: IConfigTabs[] = [
    {
        endpoint: {},
        icon: <FontIcon icon={faSensorAlert} />,
        id: 0,
        label: 'All'
    },
    {
        endpoint: { is_read: true },
        icon: <FontIcon icon={faArchive} />,
        id: 1,
        label: 'Archive'
    },
    {
        endpoint: { notification_type: 'INFO' },
        icon: <FontIcon icon={faCog} />,
        id: 2,
        label: 'Info'
    },
    {
        endpoint: { notification_type: 'ALERT' },
        icon: <FontIcon icon={faExclamationTriangle} />,
        id: 3,
        label: 'Alert'
    },
    {
        endpoint: { notification_type: 'ACTION' },
        icon: <FontIcon icon={faHandPointUp} />,
        id: 4,
        label: 'Action'
    }
];

const mainViewConfiguration: INotificationConfiguration = {
    heading: false,
    showViewingPanel: true,
    tabs: tabs
};

export default mainViewConfiguration;
