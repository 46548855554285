import { AllocationsFormValues } from "ui/allocations/allocations-personal";
import { AppController } from "../../contracts/app.contract";
import { AxiosResponse } from 'axios';
import { MutationOptions, useMutation, useQueryClient } from 'react-query';
import { toastMessage } from 'helpers/helpers';
import interceptors from '../../interceptors';
import securityWorker from '../../securityWorker';

type AllocationPostParams = { body: AllocationsFormValues };

function usePostAllocation<TError = unknown, TContext = unknown>(
    options?: Omit<
        MutationOptions<
            AxiosResponse<void, unknown>,
            ReactQueryError<TError>,
            AllocationPostParams,
            TContext
        >,
        'mutationFn'
    >
) {
    const queryClient = useQueryClient();

    return useMutation<
        AxiosResponse<void, unknown>,
        ReactQueryError<TError>,
        AllocationPostParams,
        TContext
    >(
        [],
        async ({ body }) => {
            const controller = new AppController({
                interceptors,
                securityWorker
            });
            return controller.setUserAllocations(body);
        },
        {
            ...options,
            onError: (_error, _variables, context) => {
                return toastMessage(
                    'There was an error. Please check your selections',
                    'error'
                );
            },
            onSuccess: () => {
                queryClient.invalidateQueries(['allocations'],);
            }
        }
    );
}

export default usePostAllocation;
