import { Notification } from 'core/api/contracts/contract.types';
import { Typography } from 'components';
import { useEffect, useState } from 'react';
import { useNotificationsContext } from 'ui/notifications/notifications-context';
import { useParams } from 'react-router-dom';

function NotificationView() {
    const { uuid } = useParams();
    const [notification, setNotification] = useState<Notification | null>(null);
    const { notificationData } = useNotificationsContext();

    useEffect(() => {
        if (notificationData) {
            const thisNotification = notificationData.find((n) => {
                return n.uuid === uuid;
            });
            setNotification(thisNotification);
        }
    }, [notificationData, uuid]);

    return (
        <div className="p-ncc-padding-20 gap-4 flex flex-col ">
            <div className="flex items-center">
                <Typography.Header level={2} shadow={false}>
                    {notification?.subject}
                </Typography.Header>
                <div
                    className={` ml-auto center relative select-none rounded-full bg-teal-500 px-3.5 py-1.5 align-baseline font-sans text-xs font-bold uppercase leading-none text-white badge-${notification?.notification_type?.toLowerCase()}`}
                >
                    {notification?.notification_type}
                </div>
            </div>
            <Typography.Paragraph className="text-text-blue-secondary">
                <div
                    // Doesn't seem possible to do this any other way in React
                    dangerouslySetInnerHTML={{
                        __html: notification?.body ?? ''
                    }}
                />
            </Typography.Paragraph>
        </div>
    );
}

export default NotificationView;
