import { UserWithContractAndDayRate } from 'core/api/contracts/contract.types';
import { useMemo, useState } from 'react';
import ActionsCellRender from './action-cell-renderer';
import DataTableContainer from 'components/data-table/data-table-container';
import PageLoader from 'components/loaders/page-loader';
import SearchBar from 'components/search-bar/search-bar';
import SubNavPageHeader from 'components/page-headers/admin-page-header';
import UserNameCellRender from './user-name-renderer';
import UsersTableStatusCellRenderer from './users-table-status-cell-renderer';

interface UsersTableProps {
    data: UserWithContractAndDayRate[] | undefined;
    loading: boolean;
}

const UsersTable = ({ data, loading }: UsersTableProps) => {
    const [searchTerm, setSearchTerm] = useState('');

    const onHandleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const sortedAndFilteredData = useMemo(() => {
        if (!data) return [];

        const sortedData = [...data].sort((a, b) =>
            a.username.toLowerCase().localeCompare(b.username.toLowerCase())
        );

        return sortedData.filter((user) =>
            user.username.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }, [data, searchTerm]);

    const columns = [
        {
            Cell: (props) => {
                return <UserNameCellRender context={null} {...props} />;
            },
            Header: 'User',
            accessor: 'username',
            id: 'username'
        },
        {
            Header: 'Role',
            accessor: 'roles',
            id: 'roles'
        },
        {
            Header: 'Title',
            accessor: 'contract.day_rate.name',
            id: 'title'
        },
        {
            Header: 'Contract hours',
            accessor: 'contract.contracted_hours',
            id: 'contracted_hours'
        },
        {
            Cell: (props) => {
                return (
                    <UsersTableStatusCellRenderer context={null} {...props} />
                );
            },
            Header: 'Status'
        },
        {
            Cell: (props) => {
                return <ActionsCellRender context={null} {...props} />;
            },
            Header: 'Actions'
        }
    ];

    return (
        <>
            {loading ? (
                <div className="flex items-center justify-center h-full">
                    <PageLoader
                        suppressBackground={true}
                        type="fast"
                        text="Loading users..."
                    />
                </div>
            ) : (
                <div>
                    <SubNavPageHeader pageTitle="User Management"></SubNavPageHeader>
                    <div className="p-[24px]">
                        <SearchBar
                            data-testid="input"
                            className="border border-solid rounded-md"
                            placeholder="Search on user name..."
                            onChange={onHandleSearch}
                            disabled={loading}
                        />
                    </div>
                    <DataTableContainer
                        context={null}
                        columns={columns}
                        data={
                            sortedAndFilteredData ? sortedAndFilteredData : []
                        }
                    />
                </div>
            )}
        </>
    );
};

export default UsersTable;
