import { useCallback } from 'react';
import { useQuery } from 'react-query';

import { AppController } from 'core/api/contracts/app.contract';
import interceptors from 'core/api/interceptors';
import securityWorker from 'core/api/securityWorker';

function useUsersList() {
    const request = useCallback(async () => {
        const controller = new AppController({
            interceptors,
            securityWorker
        });

        // We don't want to catch any errors here since it will broke react query "error" status
        const response = await controller.getAdminUsers();
        return response.data;
    }, []);

    return useQuery(['users', 'list'], request);
}

export default useUsersList;
