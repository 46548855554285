import { Notification } from 'core/api/contracts/contract.types';
import { useEffect, useState } from 'react';
import useNotificationsDataHook from 'core/api/hooks/notifications/use-notifications-data-hook';

interface IRemoteTrigger {
    queryParam: object;
    remoteTrigger: boolean;
}

const useNotifications = (query?) => {
    const { data, isError, isFetching, isLoading } = useNotificationsDataHook({
        query: query
    });

    const [notificationData, setNotificationData] = useState<
        Notification[] | undefined
    >(undefined);

    const [selectedNotificationInContext, setSelectedNotificationInContext] =
        useState<Notification | object>();

    useEffect(() => {
        setNotificationData(data);
    }, [data]);

    const [openNotificationsWidget, setOpenNotificationsWidget] =
        useState<boolean>(false);

    // A function to handle the click event on the toggle button
    const handleNotificationsToggle = () => {
        setOpenNotificationsWidget(!openNotificationsWidget);
    };

    const [remoteTrigger, setRemoteTrigger] = useState<IRemoteTrigger>();

    return {
        handleNotificationsToggle,
        isError,
        isFetching,
        isLoading,
        notificationData,
        openNotificationsWidget,
        remoteTrigger,
        selectedNotificationInContext,
        setNotificationData,
        setOpenNotificationsWidget,
        setRemoteTrigger,
        setSelectedNotificationInContext
    };
};

export default useNotifications;
