import { FC, useContext } from 'react';
import dayjs from 'dayjs';

import { ScheduledNotification } from 'core/api/contracts/contract.types';
import GlobalContext from './context/global-context';
import NotificationPill from './notification-pill';

interface DayProps {
    day;
    key: number;
    rowIdx: number;
}

const Day: FC<DayProps> = ({ day, rowIdx }) => {
    const {
        filteredNotifications,
        monthIndex,
        setDaySelected,
        setShowFormPane,
        setTimeSelected
    } = useContext(GlobalContext);

    function createNotification() {
        const now = dayjs();
        const formattedDay = day.format('YYYY-MM-DD');

        // Default to 08:00 for new scheduled notifications unless the day
        // selected is today, in which case default it to one hour in the future
        let formattedTime = '08:00';
        if (formattedDay === now.format('YYYY-MM-DD')) {
            const nowPlusOneHour = now.add(1, 'hour');
            formattedTime = nowPlusOneHour.format('HH:mm');
        }
        const selectedDate = dayjs(`${formattedDay}T${formattedTime}`);

        if (selectedDate.isBefore(now)) return; // Don't show the form

        setDaySelected(formattedDay);
        setTimeSelected(formattedTime);
        setShowFormPane(true);
    }

    function getBackgroundClass() {
        return day.day() === 0 || day.day() === 6
            ? 'border border-gray-200 cursor-pointer flex flex-col hover:border-ncc-general-blue bg-[#f3f4f8]'
            : 'border border-gray-200 cursor-pointer flex flex-col hover:border-ncc-general-blue';
    }

    function getDayKey() {
        return day.format('YYYY-MM-DD');
    }

    function getNotifications(): Array<ScheduledNotification> {
        return filteredNotifications.get(getDayKey()) ?? [];
    }

    function getNumberClass() {
        if (getDayKey() === dayjs().format('YYYY-MM-DD')) {
            return 'bg-blue-600 text-white font-bold rounded-full w-7 text-center';
        } else if (Math.trunc(monthIndex) !== day.month()) {
            return 'text-sm p-1 my-1 text-[#a1a5b1]';
        } else {
            return 'text-sm p-1 my-1';
        }
    }

    return (
        <div className={getBackgroundClass()} onClick={createNotification}>
            <header className="flex flex-col">
                {rowIdx === 0 && (
                    <div className="text-sm mt-1 border-b-[1px] border-utilisation-border-color-main w-full">
                        {day.format('ddd').toUpperCase()}
                    </div>
                )}
                <p className={getNumberClass()}>{day.format('DD')}</p>
            </header>
            <div className="flex-1 content-end m-[10px]">
                {getNotifications().map((notification) => {
                    return (
                        <NotificationPill
                            key={notification.uuid}
                            notification={notification}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default Day;
