import { CoreController } from 'core/api/contracts/core';
import { UserData } from 'ui/admin/user-management/user-details-form';
import { useMutation, useQueryClient } from 'react-query';

type UserPutParams = UserData;

// TODO: use common mutation.
function useEditUserDetails() {
    const queryClient = useQueryClient();
    return useMutation(
        ({ data, id }: UserPutParams) => {
            const controller = new CoreController({});
            return controller.updateUser(id, data);
        },
        {
            onError: (_error, _variables, context) => {
                // show an error message or a toast or something
            },
            onMutate: ({ data }) => {
                return { data };
            },
            onSettled: () => {
                queryClient.refetchQueries('user-data');
            }
        }
    );
}

export default useEditUserDetails;
