import { ReactElement } from 'react';
import { faDownToLine } from '@fortawesome/pro-regular-svg-icons';
import { useNavigate } from 'react-router-dom';
import Button from 'components/button/button';
import ContentBody from 'app-layout/bodies/content-body';
import FontIcon from 'components/icon/icon';
import NotificationsContent from 'ui/notifications/notifications-content';
import PageHeading from 'ui/page-headings/page-heading';
import mainViewConfiguration from './view-configs/main-view-configuration';

interface NotificationsContainerProps {}

function NotificationsContainer({ collapsed }): ReactElement {
    const navigate = useNavigate();

    return (
        <div className="flex flex-col flex-1" data-testid="base-user-page">
            <PageHeading title="Notifications">
                <div className="ml-auto mx-ncc-margin-24">
                    <Button
                        onClick={() => navigate('/settings/notifications')}
                        variant="secondary"
                        icon={<FontIcon icon={faDownToLine} className="text" />}
                    >
                        Notification Settings
                    </Button>
                </div>
            </PageHeading>
            <ContentBody>
                <div className="bg-white rounded-xl h-full">
                    <NotificationsContent
                        configuration={mainViewConfiguration}
                    ></NotificationsContent>
                </div>
            </ContentBody>
        </div>
    );
}

export default NotificationsContainer;
export type { NotificationsContainerProps };
