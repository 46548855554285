import { CheckboxProp } from 'components/form/elements/checkbox-element';
import { Typography } from 'components';
import CheckboxElement from 'components/form/elements/checkbox-element';

export interface ISettingItemProps {
    changeHandler?;
    options: CheckboxProp[];
    setting: string;
    text: string;
}

function SettingItem({
    changeHandler,
    options,
    setting,
    text
}: ISettingItemProps) {
    return (
        <li className="flex items-center border-b border-ncc-grey-30 py-ncc-padding-16">
            <div>
                <Typography.Header
                    shadow={false}
                    level={4}
                    className="text-utilisation-portal-dark"
                >
                    {setting}
                </Typography.Header>
                <p className="text-ncc-grey-70 truncate">{text}</p>
            </div>
            <div className="flex ml-auto">
                {options.map((option) => (
                    <div className="flex flex-col">
                        <CheckboxElement
                            changeHandler={changeHandler}
                            key={option.id}
                            option={option}
                        ></CheckboxElement>
                    </div>
                ))}
            </div>
        </li>
    );
}

export default SettingItem;
