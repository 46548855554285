import { CommonProps } from '@ncc-frontend/core';
import { PropsWithChildren } from 'react';
import classNames from 'classnames';

interface AppHeaderProps extends CommonProps {}

function AppHeader({
    children,
    className,
    ...restProps
}: PropsWithChildren<AppHeaderProps>) {
    const cssClasses = classNames(
        className,
        'fixed top-0 right-0 h-[60px] gap-3 flex items-center dark:bg-utilisation-page-dark z-10'
    );

    return (
        <div {...restProps} className={cssClasses}>
            {children}
        </div>
    );
}

export default AppHeader;
export type { AppHeaderProps };
