import { AppController } from 'core/api/contracts/app.contract';
import { useCallback } from 'react';
import { useQuery } from 'react-query';
import interceptors from 'core/api/interceptors';
import securityWorker from 'core/api/securityWorker';

function useTeamsData(query?: {
    line_manager_uuid?: string;
    only_incomplete: boolean;
    week?: string;
}) {
    const request = useCallback(async () => {
        const controller = new AppController({
            interceptors,
            securityWorker
        });

        const response = await controller.getAdminIncompleteAllocations(query);
        return response.data;
    }, [query]);

    return useQuery(['teams', query], request);
}

export default useTeamsData;
