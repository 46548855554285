import * as yup from 'yup';
import ChargeableComponent from './form-field-controllers/chargeable-component';
import CrudForm from 'components/form/components/crud-form';
import NameComponent from 'components/form/components/name-component';
import ShortCodeComponent from './form-field-controllers/short-code-component';
import TypeComponent from './form-field-controllers/type-component';
import useCreateProduct from 'core/api/hooks/products/use-create-product';
import useDeleteProduct from 'core/api/hooks/products/use-delete-product';
import useUpdateProduct from 'core/api/hooks/products/use-update-product';

const schema = yup.object().shape({
    is_activity: yup.boolean().required('Is activity is required'),
    name: yup.string().required('Name is required'),
    short_code: yup.string().required('Short code is required'),
    type: yup.string().required('Type field is required')
});

const ProductCrudForm = ({ crudAction, data }) => {
    const formComponents = [
        <NameComponent
            key="name"
            readOnly={crudAction === 'delete'}
            type="text"
            placeholder="Please enter a name..."
        />,
        <TypeComponent key="type" readOnly={crudAction === 'delete'} />,
        <div className="flex gap-6" key="details">
            <ShortCodeComponent
                readOnly={crudAction === 'delete'}
                type="text"
                placeholder="Please enter a short code..."
            />
            <ChargeableComponent readOnly={crudAction === 'delete'} />
        </div>
    ];

    const apiHooks = {
        create: useCreateProduct,
        delete: useDeleteProduct,
        update: useUpdateProduct
    };

    const getSuccessMessage = (action) => {
        switch (action) {
            case 'create':
                return 'Your Product was created successfully';
            case 'update':
                return 'Your Product was updated successfully';
            case 'delete':
                return 'Your Product was deleted successfully';
            default:
                return '';
        }
    };

    const onSubmitTransform = (formData) => ({
        ...formData,
        uuid: formData.uuid
    });

    return (
        <CrudForm
            crudAction={crudAction}
            data={data}
            schema={schema}
            formComponents={formComponents}
            apiHooks={apiHooks}
            getSuccessMessage={getSuccessMessage}
            onSubmitTransform={onSubmitTransform}
        />
    );
};

export default ProductCrudForm;
