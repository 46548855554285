import AdminNavigationLink from './settings-navigation-link';
import MainRouteHeading from 'ui/page-headings/main-route-heading';
import classNames from 'classnames';

const SettingsNavigation = ({ collapsed }) => {
    const cssClasses = classNames(
        'w-[336px] h-[100vh] bg-ncc-grey-10 px-5 dark:bg-utilisation-page-dark fixed',
        {
            'ml-1': collapsed,
            'ml-57': !collapsed
        }
    );
    const navigationItems = [
        {
            text: 'Notifications',
            to: '/settings/notifications'
        }
    ];

    return (
        <div className={cssClasses}>
            <MainRouteHeading
                level={1}
                title="Settings"
                shadow={false}
            ></MainRouteHeading>
            {navigationItems.map((item, i) => {
                return (
                    <AdminNavigationLink
                        key={item.text}
                        text={item.text}
                        to={item.to}
                    ></AdminNavigationLink>
                );
            })}
        </div>
    );
};

export default SettingsNavigation;
