import { UserBase } from 'core/api/contracts/contract.types';
import { useEffect, useState } from 'react';
import { useLocalStorage } from '@ncc-frontend/core';

const useUser = () => {

    const userFromLocalStorage = useLocalStorage('user', JSON.stringify(null));
    const [user, setUser] = useState<UserBase | null>(null);
    const [contract, setContract] = useState<string>('');
    const [week, setWeek] = useState<string>('');
    const [uuid] = useState<string>('')

    useEffect(() => {
        setUser(userFromLocalStorage[0]['user']);
    }, [userFromLocalStorage]);

    return {
        contract,
        setContract,
        setUser,
        setWeek,
        user,
        uuid,
        week
    };
};

export default useUser;


