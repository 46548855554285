import { FormProvider, useForm } from 'react-hook-form';
import { ResultModal } from 'components';
import { useEffect, useState } from 'react';
import { useModals } from '@ncc-frontend/core';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from 'components/button/button';
import PageLoader from 'components/loaders/page-loader';

const CrudForm = ({
    apiHooks,
    crudAction,
    data,
    formComponents,
    getSuccessMessage,
    onSubmitTransform,
    schema
}) => {
    const { pop, push } = useModals();
    const [readOnly, setReadOnly] = useState(false); // eslint-disable-line @typescript-eslint/no-unused-vars
    const [apiLoading, setApiLoading] = useState(false);

    const methods = useForm({
        defaultValues: data,
        mode: 'onTouched',
        resolver: yupResolver(schema)
    });

    useEffect(() => {
        methods.reset(data);
        if (crudAction === 'delete') setReadOnly(true);
    }, [crudAction, data, methods]);

    const onHandleApiError = (err) => {
        if (err.response.status !== 400) {
            pop();
            push(ResultModal, {
                description: 'Something went wrong. Close and try again.',
                title: 'Changes didn’t happen',
                variant: 'error'
            });
        }
    };

    const onHandleApiSuccess = () => {
        pop();
        methods.reset();
        push(ResultModal, {
            description: getSuccessMessage(crudAction),
            title: 'Action successful',
            variant: 'success'
        });
    };

    const { isLoading, mutate: create } = apiHooks.create({
        onError: onHandleApiError,
        onSuccess: onHandleApiSuccess
    });

    const { mutate: update } = apiHooks.update({
        onError: onHandleApiError,
        onSuccess: onHandleApiSuccess
    });

    const { mutate: deleteAction } = apiHooks.delete({
        onError: onHandleApiError,
        onSuccess: onHandleApiSuccess
    });

    useEffect(() => {
        setApiLoading(isLoading);
    }, [isLoading]);

    const formSubmitHandler = (formData) => {
        const transformedData = onSubmitTransform
            ? onSubmitTransform(formData)
            : formData;

        switch (crudAction) {
            case 'create':
                create({ data: transformedData });
                break;
            case 'update':
                update({ data: transformedData });
                break;
            case 'delete':
                deleteAction({ query: { uuid: formData.uuid } });
                break;
            default:
                break;
        }
    };

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(formSubmitHandler)} noValidate>
                {apiLoading ? (
                    <PageLoader
                        suppressBackground={true}
                        type="fast"
                        text="Submitting request..."
                    />
                ) : (
                    <>
                        <div className="flex flex-col gap-6 text-left">
                            {formComponents.map((Component, index) => (
                                <div key={index}>{Component}</div>
                            ))}
                        </div>
                        <div className="mt-5">
                            <div className="flex gap-2 justify-end">
                                <Button onClick={pop} variant="tertiary">
                                    Cancel
                                </Button>
                                {crudAction === 'delete' ? (
                                    <Button variant="danger">Delete</Button>
                                ) : (
                                    <Button variant="primary">Save</Button>
                                )}
                            </div>
                        </div>
                    </>
                )}
            </form>
        </FormProvider>
    );
};

export default CrudForm;
