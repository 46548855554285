import { useNavigate } from 'react-router-dom';

interface INotificationOption {
    action: string;
    id: string;
    label: string;
}

export const notificationsOptions: INotificationOption[] = [
    {
        action: '/notifications',
        id: '1',
        label: 'View all Notifications'
    }
];

const RenderNotificationsOptions = (option: INotificationOption) => {
    const navigate = useNavigate();

    const onHandleClick = (fn) => {
        navigate(fn);
    };

    return (
        <div className="w-full" onClick={() => onHandleClick(option.action)}>
            {option.label}
        </div>
    );
};

export { RenderNotificationsOptions };
