import { Product } from 'core/api/contracts/contract.types';
import { ProductsTableContext } from './products-table-context';
import { ResultModal } from 'components';
import { useCallback, useMemo } from 'react';
import { useModals } from '@ncc-frontend/core';
import { useNavigate } from 'react-router-dom';
import ActionRenderer from 'components/action-renderer/ActionRenderer';
import ActivityActionRenderer from './activity-action-renderer';
import AddProductButton from './add-product-button';
import DataTableContainer from 'components/data-table/data-table-container';
import ModalContainer from 'components/modal/modal-container';
import ProductCrudForm from './products-crud-form';
import SubNavPageHeader from 'components/page-headers/admin-page-header';
import TypeRenderer from './type-renderer';
import useProductsDelete from 'core/api/hooks/products/use-delete-product';

interface ProductTableProps {
    error?: number;
    loading?: boolean;
    products: Product[] | undefined;
    refetch?;
}

const ProductsTable = ({
    error,
    loading,
    products,
    refetch
}: ProductTableProps) => {
    const { push } = useModals();
    const goTo = useNavigate();

    const { mutate: requestProductDelete } = useProductsDelete({
        onError: () => {
            push(ResultModal, {
                description: 'Sorry you dont have permission to delete this',
                title: 'Could not delete cost level',
                variant: 'error'
            });
        },
        onSuccess: () => {
            refetch();
        }
    });

    const columns = [
        {
            Header: 'Name',
            accessor: 'name',
            id: 'name'
        },
        {
            Cell: (props) => {
                return (
                    <ActivityActionRenderer context={tableContext} {...props} />
                );
            },
            Header: 'Chargeable',
            accessor: 'is_activity',
            id: 'is_activity'
        },
        {
            Cell: (props) => {
                return (
                    <TypeRenderer
                        context={tableContext}
                        {...props['row'].original}
                    />
                );
            },
            Header: 'Type',
            accessor: 'type',
            id: 'type'
        },
        {
            Cell: (props) => {
                return <ActionRenderer context={tableContext} {...props} />;
            },
            Header: 'Actions'
        }
    ];

    const tableContext = useMemo<ProductsTableContext>(
        () => ({
            goTo,
            push,
            refetch,
            requestProductDelete
        }),
        [goTo, push, refetch, requestProductDelete]
    );

    const addNewProduct = useCallback(() => {
        push(ModalContainer, {
            content: ProductCrudForm,
            crudAction: 'create',
            data: null,
            title: 'Add new product'
        });
    }, [push]);

    return (
        <div>
            <SubNavPageHeader pageTitle="Products">
                <AddProductButton
                    disabled={false}
                    addNewProduct={addNewProduct}
                ></AddProductButton>
            </SubNavPageHeader>

            <DataTableContainer
                context={tableContext}
                columns={columns}
                data={products ? products : []}
            />
        </div>
    );
};

export default ProductsTable;
