const Avatar = () => {
    const userObject = JSON.parse(localStorage.getItem('user') ?? '');

    if (!userObject) return null;

    const getUserInitials = () => {
        const { display_name, family_name, given_name } = userObject.user;

        if (given_name === null || family_name === null) {
            const initialsFromArray = display_name
                .split(' ')
                .map((word) => word[0])
                .join('');
            return initialsFromArray;
        } else {
            const firstInitial = given_name?.charAt(0) ?? '';
            const secondInitial = family_name?.charAt(0) ?? '';

            return `${firstInitial}${secondInitial}`;
        }
    };

    const userInitials = getUserInitials();

    return (
        <div className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
            <div className="bg-[#A3367C] text-white rounded-full text-sm p-2 flex">
                {userInitials}
            </div>
        </div>
    );
};

export default Avatar;
