// Importing core components
import { useState } from 'react';
import QuillEditor from 'react-quill';

// Importing styles
import './editor.styles.scss';
import 'react-quill/dist/quill.snow.css';

const Editor = ({ field, maxLength, readOnly }) => {
    const [maxLengthExceeded, setMaxLengthExceeded] = useState(false);

    return (
        <div>
            <div className="editor-wrapper">
                <QuillEditor
                    className="editor"
                    readOnly={readOnly}
                    theme="snow"
                    value={field.value}
                    onChange={(val) => {
                        field.onChange(val);
                        if (val.length > maxLength) {
                            setMaxLengthExceeded(true);
                        } else {
                            setMaxLengthExceeded(false);
                        }
                    }}
                />
            </div>
            {maxLengthExceeded && (
                <span className="text-red-800">Max length exceeded</span>
            )}
        </div>
    );
};

export default Editor;
