import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/pro-solid-svg-icons';
import { useNotificationsContext } from 'ui/notifications/notifications-context';
import OnOutsideClick from 'common/use-outside-click';
import React, { useState } from 'react';

interface IPopout {
    content: React.ReactNode;
    notificationLength?: number | undefined;
    trigger?: React.ReactNode;
}

// A custom dropdown menu component that takes an array of options as props
const Popout = ({ content, notificationLength, trigger }: IPopout) => {
    // A state variable to toggle the menu visibility
    const [show, setShow] = useState<boolean>(false);
    const { openNotificationsWidget, setOpenNotificationsWidget } =
        useNotificationsContext();

    // A function to handle the click event on the toggle button
    const handleToggle = () => {
        setShow(!show);
        setOpenNotificationsWidget(!openNotificationsWidget);
    };

    const handleOutsideClick = () => {
        setShow(false);
        setOpenNotificationsWidget(false);
    };

    return (
        <div className="dropdown ml-auto">
            <button className="dropdown-toggle" onClick={handleToggle}>
                <span className="dropdown-arrow">
                    {trigger ? (
                        <div className="relative">
                            {trigger}{' '}
                            {notificationLength && notificationLength > 0 ? (
                                <span className="rounded-full bg-utilisation-red-100 px-1.5 py-1 align-baseline font-sans text-[8px] font-bold uppercase leading-none text-white absolute top-[-10px] left-[5px]">
                                    {`${notificationLength}`}
                                </span>
                            ) : null}
                        </div>
                    ) : (
                        <FontAwesomeIcon
                            icon={faEllipsis}
                            className="ml-auto cursor-pointer"
                        ></FontAwesomeIcon>
                    )}
                </span>
            </button>
            {show && (
                <OnOutsideClick
                    children={
                        <div className="dropdown-menu absolute right-[12px] bg-white border border-1 rounded-[10px] drop-shadow-md">
                            {content}
                        </div>
                    }
                    onOutsideClick={() => handleOutsideClick()}
                ></OnOutsideClick>
            )}
        </div>
    );
};

export default Popout;
