import { useCallback } from 'react';

import { AppController } from 'core/api/contracts/app.contract';
import { useQuery } from 'react-query';
import interceptors from 'core/api/interceptors';
import securityWorker from 'core/api/securityWorker';

function useUserDetails(userUuid: string) {
    const request = useCallback(async () => {
        const controller = new AppController({
            interceptors,
            securityWorker
        });

        // We don't want to catch any errors here since it will broke react query "error" status
        const response = await controller.getAdminUserModel(userUuid);
        return response.data;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return useQuery(['user-data', 'details', userUuid], request);
}

export default useUserDetails;
