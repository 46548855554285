import { PropsWithChildren, useMemo } from 'react';
import _ from 'lodash';

import {
    TypographyHeaderProps as CoreTypographyHeaderProps,
    TailwindStyle,
    Typography
} from '@ncc-frontend/core';

interface TypographyHeaderProps extends CoreTypographyHeaderProps {
    level: 1 | 2 | 3 | 4 | 5 | 6;
    shadow: boolean;
}

function TypographyHeader({
    children,
    level,
    shadow,
    tailwindStyle,
    ...restProps
}: PropsWithChildren<TypographyHeaderProps>) {
    let boxShadow = 'none';
    if (level === 1 && shadow) boxShadow = 'shadow-header';

    let textSize = 'text-xs';
    let textWeight = 'font-normal';

    switch (level) {
        case 1:
            textSize = 'text-h1 font-SemiBold';
            textWeight = 'font-semibold';
            break;
        case 2:
            textSize = 'text-h2 font-SemiBold';
            textWeight = 'font-medium';
            break;
        case 3:
            textSize = 'text-h3 font-Medium';
            textWeight = 'font-medium';
            break;
        case 4:
            textSize = 'text-h4 font-Medium';
            textWeight = 'font-normal';
            break;
        default:
            break;
    }

    const mergedTailwindStyle = useMemo<TailwindStyle>(
        () =>
            _.merge<TailwindStyle, TailwindStyle | undefined>(
                {
                    background: 'dark:bg-ncc-portal-grey-100',
                    boxShadow: boxShadow,
                    display: 'flex',
                    height: 'auto',
                    items: 'items-center',
                    textSize: textSize,
                    textWeight: textWeight
                },
                tailwindStyle
            ),
        [boxShadow, tailwindStyle, textSize, textWeight]
    );

    return (
        <Typography.Header
            level={level}
            tailwindStyle={mergedTailwindStyle}
            {...restProps}
        >
            {children}
        </Typography.Header>
    );
}

export default TypographyHeader;
export type { TypographyHeaderProps };
