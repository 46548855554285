import { Button } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { useCallback } from 'react';
import ModalContainer from 'components/modal/modal-container';

const ActionRenderer = ({
    context: { push },
    entityType,
    formComponent,
    ...props
}) => {
    const tableAction = useCallback(
        (action) => {
            return push(ModalContainer, {
                content: formComponent,
                crudAction: action,
                data: props['row'].original,
                title: `${action} ${entityType}`
            });
        },
        [props, push, formComponent, entityType]
    );

    return (
        <div className="flex h-full items-center gap-2.5">
            <Button onClick={() => tableAction('update')} variant="tertiary">
                <FontAwesomeIcon icon={faPencil} />
                <p className="text-[12px]">Edit</p>
            </Button>
            <Button variant="tertiary" onClick={() => tableAction('delete')}>
                <FontAwesomeIcon icon={faTrash} />
                <p className="text-[12px]">Delete</p>
            </Button>
        </div>
    );
};

export default ActionRenderer;
