import { useNavigate } from 'react-router-dom';

interface IAccountOption {
    action: string;
    id: string;
    label: string;
}

export const accountOptions: IAccountOption[] = [
    { action: '/login', id: '1', label: 'Logout' }
];

const RenderAccountOptions = (option: IAccountOption) => {
    const navigate = useNavigate();

    const onHandleClick = (fn) => {
        navigate(fn);
    };

    return (
        <div className="w-full" onClick={() => onHandleClick(option.action)}>
            {option.label}
        </div>
    );
};

export { RenderAccountOptions };
