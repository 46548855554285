import { DayRate, UserBase } from 'core/api/contracts/contract.types';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useProductReportsContext } from 'ui/admin/product-reports/product-reports-context';
import AddProductReportButton from 'ui/admin/product-reports/add-product-report-button';

import { ProductReportsTableContext } from 'ui/admin/product-reports/product-reports-table-context';
import Alert from 'components/alert/alert';
import Button from 'components/button/button';
import ContentBody from 'app-layout/bodies/content-body';
import DatePickerButton from './datepicker-button';
import PageHeading from 'ui/page-headings/page-heading';
import ProductReportWidget from '../../../ui/admin/product-reports/product-report-widget';
import ProductReportsAddModal from 'ui/admin/product-reports/product-reports-add-modal';

type FiltersType = {
    day_rates?: {
        name?: string;
        /** @format uuid */
        uuid?: string;
    }[];
    products?: {
        name?: string;
        uuid?: string;
    }[];
    users?: UserBase[];
};

type AllocatedUserProps = {
    cost?: number;
    day_rate?: DayRate;
    hours?: number;
    user?: UserBase;
};

type ReportItem = {
    allocated_users?: AllocatedUserProps[];
    created_at?: string;
    is_activity?: boolean;
    name?: string;
    short_code?: string;
    updated_at?: string;
    uuid?: string;
};

type ReportProps = {
    products: ReportItem[];
};
type ReportType = {
    report: ReportProps;
};

type ProductReportsPageProps = {
    filters: FiltersType;
    report: ReportType;
};

function ProductReportsPage({ collapsed }): ReactElement {
    const [title, setTitle] = useState('');

    const {
        endDate,
        noReportsAvailable,
        push,
        reportData,
        setEndDate,
        setNoReportsAvailable,
        setReportData,
        setStartDate,
        startDate
    } = useProductReportsContext();

    const productReportsContext = useMemo<ProductReportsTableContext>(
        () => ({
            setEndDate,
            setStartDate,
            setTitle,
            title
        }),
        [setEndDate, setStartDate, title]
    );

    const [disabled, setDisabled] = useState(false);

    const addNewProductReport = useCallback(() => {
        push(ProductReportsAddModal, {
            endDate,
            setNoReportsAvailable,
            setReportData,
            startDate
        });
    }, [push, endDate, setNoReportsAvailable, setReportData, startDate]);

    const handleDeleteReportItem = (i) => {
        const newReportData = reportData;

        const newData = newReportData.report.products.filter(
            (_product, index) => {
                return index !== i;
            }
        );

        setReportData({
            filters: null,
            report: {
                products: newData
            }
        });
    };

    useEffect(() => {
        if (endDate || startDate === '') {
            setDisabled(true);
        }
        if (endDate || startDate !== '') {
            setDisabled(false);
        }
    }, [endDate, startDate]);

    const resetCalendarDates = () => {
        setEndDate('');
        setStartDate('');
        setTitle('');
        setReportData({
            filters: null,
            report: {
                products: []
            }
        });
    };

    return (
        <div className="flex flex-col flex-1">
            <PageHeading title="Product reports">
                <div className="ml-auto mx-ncc-margin-24">
                    <AddProductReportButton
                        disabled={disabled}
                        addNewProductReport={addNewProductReport}
                    ></AddProductReportButton>
                </div>
            </PageHeading>
            <ContentBody>
                <div className="grid grid-cols-4">
                    <DatePickerButton
                        context={productReportsContext}
                    ></DatePickerButton>
                    <Button
                        onClick={() => resetCalendarDates()}
                        className="w-[150px] ml-2"
                    >
                        Clear / Reset
                    </Button>
                </div>

                {noReportsAvailable && (
                    <div className="flex justify-center mt-10 text-ncc-grey-70">
                        <Alert
                            variant="error"
                            className="w-full rounded-t-[8px]"
                        >
                            Unfortunately there are no reports to display for
                            those selections. Please try again.
                        </Alert>
                    </div>
                )}

                {reportData !== undefined ? (
                    reportData?.report.products.map((report, index) => {
                        return (
                            <ProductReportWidget
                                key={index}
                                report={report}
                                index={index}
                                onHandleDeleteReportItem={
                                    handleDeleteReportItem
                                }
                            ></ProductReportWidget>
                        );
                    })
                ) : (
                    <div className="flex justify-center mt-10 text-ncc-grey-70">
                        <Alert
                            variant="info"
                            className="w-full rounded-t-[8px]"
                        >
                            Please select and confirm a date range, then add a
                            product(s) to generate and view a report. If there
                            are reports available to view for your selection
                            then they will appear here.
                        </Alert>
                    </div>
                )}
            </ContentBody>
        </div>
    );
}

export default ProductReportsPage;
export type {
    ProductReportsPageProps,
    ReportType,
    FiltersType,
    ReportProps,
    ReportItem,
    AllocatedUserProps
};
