import { AxiosResponse } from 'axios';
import { MutationOptions, useMutation, useQueryClient } from 'react-query';

import { ActivityGroup } from 'core/api/contracts/contract.types';
import { CoreController } from 'core/api/contracts/core';
import interceptors from 'core/api/interceptors';
import securityWorker from 'core/api/securityWorker';

type CreateActivityGroupParams = {
    data;
};

function useCreateActivityGroup<TError = unknown, TContext = unknown>(
    options?: Omit<
        MutationOptions<
            AxiosResponse<ActivityGroup>,
            ReactQueryError<TError>,
            CreateActivityGroupParams,
            TContext
        >,
        'mutationFn'
    >
) {
    const queryClient = useQueryClient();

    return useMutation(
        [],
        async ({ data }: CreateActivityGroupParams) => {
            const controller = new CoreController({
                interceptors,
                securityWorker
            });

            return controller.postActivityGroup(data);
        },
        {
            ...options,
            onSettled: (data, error, variables, context) => {
                options?.onSettled?.(data, error, variables, context);
                queryClient.invalidateQueries({
                    queryKey: ['activity-groups']
                });
            }
        }
    );
}

export default useCreateActivityGroup;
export type { CreateActivityGroupParams };
