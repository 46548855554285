import { Notification } from 'core/api/contracts/contract.types';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Typography } from 'components';
import { faCheckDouble, faNotebook } from '@fortawesome/pro-regular-svg-icons';
import { useEffect } from 'react';
import { useNotificationsContext } from './notifications-context';
import FontIcon from 'components/icon/icon';
import NotificationItem from './notification-item';
import useNotifications from './useNotifications';
import useUpdateUserNotification from 'core/api/hooks/notifications/use-update-user-notification';

const NoNotificationsToShow = () => {
    return (
        <div className="flex flex-col h-full w-full items-center justify-center gap-4 p-ncc-padding-24">
            <FontIcon
                icon={faNotebook}
                className="text-7xl text-ncc-grey-30"
            ></FontIcon>
            <Typography.Header
                level={4}
                shadow={false}
                className="text-ncc-grey-50"
            >
                0 Notifications to show
            </Typography.Header>
        </div>
    );
};

const MarkAllAsRead = ({ notifications, updateNotification }) => {
    const markAllAsRead = () => {
        const filteredNotifications: Notification[] = notifications.filter(
            (n) => {
                return !n.is_read;
            }
        );

        async function updateNotifications() {
            for (const unreadNotification of filteredNotifications) {
                updateNotification({
                    data: {
                        body: unreadNotification.body,
                        is_read: true,
                        notification_type: unreadNotification.notification_type,
                        sent_at: unreadNotification.sent_at,
                        subject: unreadNotification.subject
                    },
                    notification_uuid: unreadNotification.uuid
                });
            }
        }

        updateNotifications();
    };

    return (
        <div className="flex items-center border-t border-solid border-ncc-grey-20 mt-auto p-ncc-padding-12">
            <div className="flex ml-auto gap-2 text-ncc-general-blue">
                <div>
                    <FontIcon icon={faCheckDouble} />
                </div>
                <div>
                    <Typography.Link onClick={() => markAllAsRead()}>
                        Mark all as read
                    </Typography.Link>
                </div>
            </div>
        </div>
    );
};

const NotificationListItem = ({ notification, onHandleItemClick }) => {
    const { uuid } = useParams();

    return (
        <li
            key={notification.uuid}
            className={` border-b border-solid border-ncc-grey-20 p-ncc-padding-12 hover:bg-ncc-grey-10 cursor-pointer w-[380px] transition-all ${
                uuid
                    ? uuid === notification.uuid
                        ? 'bg-ncc-grey-10'
                        : ''
                    : null
            }`}
        >
            <NotificationItem
                onHandleClick={onHandleItemClick}
                notification={notification}
            ></NotificationItem>
        </li>
    );
};

const NotificationSystemFilterResults = ({ query, showViewingPanel }) => {
    const { setNotificationData } = useNotificationsContext();
    const { isFetching, isLoading, notificationData } = useNotifications({
        query: query
    });

    useEffect(() => {
        setNotificationData(notificationData);
    }, [notificationData, setNotificationData]);

    const location = useLocation();
    const navigate = useNavigate();
    const { uuid } = useParams();

    const { mutate: updateNotification } = useUpdateUserNotification<{
        errors;
    }>({});

    const onHandleItemClick = (notification) => {
        navigate(`/notifications/${notification.uuid}`);

        if (!notification.is_read) {
            updateNotification({
                data: {
                    body: notification.body,
                    is_read: true,
                    notification_type: notification.notification_type,
                    sent_at: notification.sent_at,
                    subject: notification.subject
                },
                notification_uuid: notification.uuid
            });
        }
    };

    useEffect(() => {
        // If uuid is not defined
        if (!uuid) {
            // Check if notificationData exists and the current path includes 'notifications'
            if (
                notificationData &&
                location.pathname.includes('notifications')
            ) {
                // This needs looking at and improving as the context isnt ready in time.
                // Need a much nicer solution than the timer but for now its "okay"
                setTimeout(() => {
                    // Redirect to the first notification
                    navigate(`/notifications/${notificationData[0].uuid}`);
                }, 1000);
            }
        }
    }, [location, navigate, notificationData, uuid]);

    return (
        <div className="flex h-[calc(100%-50px)]">
            {!notificationData && (
                <NoNotificationsToShow></NoNotificationsToShow>
            )}
            <div className="flex flex-col border-r">
                <ul className="max-h-[600px] overflow-scroll">
                    {notificationData &&
                        notificationData?.map((notification) => (
                            <NotificationListItem
                                key={notification.uuid}
                                onHandleItemClick={onHandleItemClick}
                                notification={notification}
                            ></NotificationListItem>
                        ))}
                </ul>
                {notificationData && (
                    <MarkAllAsRead
                        updateNotification={updateNotification}
                        notifications={notificationData}
                    ></MarkAllAsRead>
                )}
            </div>
            {showViewingPanel && (
                <div className="grow">
                    {isLoading || isFetching ? (
                        <p>Loading...</p>
                    ) : (
                        <div>
                            <Outlet></Outlet>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default NotificationSystemFilterResults;
