import { PropsWithChildren } from 'react';
import { Typography } from 'components';
import classNames from 'classnames';

interface ISubNavPageHeader {
    pageTitle: string;
}

const SubNavPageHeader = ({
    children,
    pageTitle
}: PropsWithChildren<ISubNavPageHeader>) => {
    const cssClasses = classNames(
        'flex items-center mt-ncc-margin-24 ml-ncc-margin-24'
    );

    return (
        <div className={cssClasses}>
            <Typography.Header level={1} shadow={false}>
                {pageTitle}
            </Typography.Header>
            <div className="flex flex-grow justify-end">{children}</div>
        </div>
    );
};

export default SubNavPageHeader;
