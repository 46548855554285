import { Contract } from 'core/api/contracts/contract.types';
import { CoreController } from 'core/api/contracts/core';
import { useMutation, useQueryClient } from 'react-query';

type ContractPutParams = ContractData;

type ContractData = {
    contractData: Contract;
};

// TODO: use common mutation.
function useCreateContract() {
    const queryClient = useQueryClient();
    return useMutation(
        ({ contractData }: ContractPutParams) => {
            const controller = new CoreController({});
            return controller.createContract(contractData);
        },
        {
            onError: (_error, _variables, context) => {
                // show an error message or a toast or something
            },
            onMutate: ({ contractData }) => {
                return { contractData };
            },
            onSettled: () => {
                queryClient.refetchQueries('contract-data');
            }
        }
    );
}

export default useCreateContract;
