import { IConfigTabs, INotificationConfiguration } from './config.types';
import { faArchive, faSensorAlert } from '@fortawesome/pro-regular-svg-icons';
import FontIcon from 'components/icon/icon';

const tabs: IConfigTabs[] = [
    {
        endpoint: { is_read: false },
        icon: <FontIcon icon={faSensorAlert} />,
        id: 0,
        label: 'New'
    },
    {
        endpoint: { is_read: true },
        icon: <FontIcon icon={faArchive} />,
        id: 1,
        label: 'Archive'
    }
];

const modalViewConfiguration: INotificationConfiguration = {
    heading: true,
    showViewingPanel: false,
    tabs: tabs
};

export default modalViewConfiguration;
