import { Typography } from 'components';
import { calculateTimeDifference } from 'helpers/helpers';
import {
    faExclamationTriangle,
    faHandPointUp,
    faInfo
} from '@fortawesome/pro-regular-svg-icons';
import FontIcon from 'components/icon/icon';
import classNames from 'classnames';

const NotificationItem = ({ notification, onHandleClick }) => {
    const { days, hours, minutes } = calculateTimeDifference(
        new Date(notification.sent_at as string),
        new Date()
    );

    const itemClassNames = classNames('flex items-center relative gap-2');

    const getIconBasedOnType = (type) => {
        switch (type) {
            case 'ALERT':
                return (
                    <FontIcon
                        icon={faExclamationTriangle}
                        className="bg-utilisation-yellow-100 rounded-full py-2 w-[32px]"
                    ></FontIcon>
                );
            case 'INFO':
                return (
                    <FontIcon
                        icon={faInfo}
                        className="bg-utilisation-info rounded-full py-2 w-[32px]"
                    ></FontIcon>
                );
            case 'ACTION':
                return (
                    <FontIcon
                        icon={faHandPointUp}
                        className="bg-utilisation-red-100 rounded-full py-2 w-[32px]"
                    ></FontIcon>
                );
            default:
                break;
        }
    };

    return (
        <div
            onClick={() => onHandleClick(notification)}
            className={itemClassNames}
        >
            {!notification.is_read && (
                <span className="absolute read-indicator left-2 top-[-10px] text-ncc-general-blue text-2xl">
                    •
                </span>
            )}
            <div className="d-block ml-ncc-margin-24 relative text-white">
                {getIconBasedOnType(notification.notification_type)}
            </div>
            <div className="w-full">
                <div className="flex">
                    <Typography.Header
                        shadow={false}
                        level={4}
                        className="text-utilisation-portal-dark"
                    >
                        <p>{notification.notification_type}</p>
                    </Typography.Header>
                    <div className="ml-auto text-ncc-grey-70 text-[11px]">
                        {`${days}d, ${hours}hrs, ${minutes}m`}
                    </div>
                </div>

                <div>
                    <p className="text-ncc-grey-70 truncate">
                        {notification.subject}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default NotificationItem;
