import { AxiosResponse } from 'axios';
import { MutationOptions, useMutation, useQueryClient } from 'react-query';

import { AppController } from 'core/api/contracts/app.contract';
import { Notification } from 'core/api/contracts/contract.types';
import interceptors from 'core/api/interceptors';
import securityWorker from 'core/api/securityWorker';

type IUpdateUserNotification = {
    data: Notification;
    notification_uuid;
};

function useUpdateUserNotification<TError = unknown, TContext = unknown>(
    options?: Omit<
        MutationOptions<
            AxiosResponse<Notification>,
            ReactQueryError<TError>,
            IUpdateUserNotification,
            TContext
        >,
        'mutationFn'
    >
) {
    const queryClient = useQueryClient();

    return useMutation(
        [],
        async ({ data, notification_uuid }: IUpdateUserNotification) => {
            const controller = new AppController({
                interceptors,
                securityWorker
            });

            return controller.updateNotification(notification_uuid, data);
        },
        {
            ...options,
            onSettled: (data, error, variables, context) => {
                options?.onSettled?.(data, error, variables, context);
                queryClient.refetchQueries('day-rates');
                queryClient.invalidateQueries({ queryKey: ['notifications'] });
            }
        }
    );
}

export default useUpdateUserNotification;
