import { useCallback } from 'react';
import { useQuery } from 'react-query';
// import useProductsMockData from 'ui/admin/products/products-mock-data';

import { CoreController } from 'core/api/contracts/core';
import interceptors from 'core/api/interceptors';
import securityWorker from 'core/api/securityWorker';

function useProducts() {
    const request = useCallback(async () => {
        const controller = new CoreController({
            interceptors,
            securityWorker
        });
        // return await new Promise<Product[]>((resolve) =>
        //     setTimeout(() => resolve(useProductsMockData), 2000)
        // );

        // We don't want to catch any errors here since it will broke react query "error" status
        const response = await controller.getProducts();
        return response.data;
    }, []);

    return useQuery(['products', 'list'], request);
}

export default useProducts;
