import { AxiosResponse } from 'axios';
import { MutationOptions, useMutation, useQueryClient } from 'react-query';

import { CoreController } from 'core/api/contracts/core';
import { DayRate } from 'core/api/contracts/contract.types';
import interceptors from 'core/api/interceptors';
import securityWorker from 'core/api/securityWorker';

type UpdateCostLevelParams = {
    data: {
        cost: number;
        name: string;
        uuid: string;
    };
    dayrate_uuid: string;
};

function useUpdateCostLevel<TError = unknown, TContext = unknown>(
    options?: Omit<
        MutationOptions<
            AxiosResponse<DayRate>,
            ReactQueryError<TError>,
            UpdateCostLevelParams,
            TContext
        >,
        'mutationFn'
    >
) {
    const queryClient = useQueryClient();

    return useMutation(
        [],
        async ({ data, dayrate_uuid }: UpdateCostLevelParams) => {
            const controller = new CoreController({
                interceptors,
                securityWorker
            });

            return controller.updateDayRate(dayrate_uuid, data);
        },
        {
            ...options,
            onSettled: (data, error, variables, context) => {
                options?.onSettled?.(data, error, variables, context);
                queryClient.refetchQueries('day-rates');
                queryClient.invalidateQueries({ queryKey: ['day-rates'] });
            }
        }
    );
}

export default useUpdateCostLevel;
export type { UpdateCostLevelParams };
