import React, { FC, Fragment, useContext, useEffect } from 'react';
import dayjs from 'dayjs';

import { ScheduledNotification } from 'core/api/contracts/contract.types';
import Day from './day';
import GlobalContext from './context/global-context';
import PageLoader from 'components/loaders/page-loader';
import useAdminNotifications from 'core/api/hooks/notifications/use-get-admin-notifications';

interface MonthProps {
    month;
    queryParams;
}

const Month: FC<MonthProps> = ({ month, queryParams }) => {
    const { data, isFetching, isLoading } = useAdminNotifications({
        query: queryParams
    });
    const { setFilteredNotifications } = useContext(GlobalContext);

    useEffect(() => {
        if (!data) return;

        try {
            const scheduledNotifications = data as ScheduledNotification[];
            const filteredNotifications = scheduledNotifications.reduce(
                (acc, notification) => {
                    const key = dayjs(notification.to_send_at).format(
                        'YYYY-MM-DD'
                    );
                    if (!acc.has(key)) {
                        acc.set(key, []);
                    }
                    acc.get(key)?.push(notification);
                    return acc;
                },
                new Map<string, Array<ScheduledNotification>>()
            );

            setFilteredNotifications(filteredNotifications);
        } catch (error) {
            console.error('Error:', error);
            setFilteredNotifications(new Map());
        }
    }, [data, setFilteredNotifications]);

    return (
        <>
            {isLoading || isFetching ? (
                <div className="flex items-center justify-center h-full w-full">
                    <PageLoader
                        suppressBackground={true}
                        type="fast"
                        text={'Fetching notifications...'}
                    ></PageLoader>
                </div>
            ) : (
                <div className="flex-1 grid grid-cols-7 grid-rows-5">
                    {month.map((row: Array<number>, i: number) => (
                        <Fragment key={i}>
                            {row.map((day: number, idx: number) => (
                                <Day day={day} key={idx} rowIdx={i} />
                            ))}
                        </Fragment>
                    ))}
                </div>
            )}
        </>
    );
};

export default Month;
