import { CheckboxProp } from 'components/form/elements/checkbox-element';

import {
    UserBase,
    UserNotificationPreferences
} from 'core/api/contracts/contract.types';

import React, { useState } from 'react';
import SettingItem from 'pages/settings/setting-item';
import SettingsPageContainer from 'pages/settings/settings-page-container';
import SubNavPageHeader from 'components/page-headers/admin-page-header';
import useUpdateNotificationPreferences from 'core/api/hooks/notifications/use-update-notification-preferences';

export interface INotificationsPageProps {
    collapsed: boolean;
}

function NotificationsPage(props: INotificationsPageProps) {
    const notificationPreferences: UserNotificationPreferences = JSON.parse(
        localStorage.getItem('user') ?? ''
    ).user.notification_preferences;

    const { mutate: updateNotificationPreferences } =
        useUpdateNotificationPreferences();

    const [actionApp, setActionApp] = useState<CheckboxProp>(() => {
        const prop: CheckboxProp = {
            id: 'action.app',
            name: 'System',
            value: notificationPreferences.action?.app ?? false
        };
        return prop;
    });

    const [actionEmail, setActionEmail] = useState<CheckboxProp>(() => {
        const prop: CheckboxProp = {
            id: 'action.email',
            name: 'Email',
            value: notificationPreferences.action?.email ?? false
        };
        return prop;
    });

    const [alertApp, setAlertApp] = useState<CheckboxProp>(() => {
        const prop: CheckboxProp = {
            id: 'alert.app',
            name: 'System',
            value: notificationPreferences.alert?.app ?? false
        };
        return prop;
    });

    const [alertEmail, setAlertEmail] = useState<CheckboxProp>(() => {
        const prop: CheckboxProp = {
            id: 'alert.email',
            name: 'Email',
            value: notificationPreferences.alert?.email ?? false
        };
        return prop;
    });

    const [notificationGrouping, setNotificationGrouping] =
        useState<CheckboxProp>(() => {
            const prop: CheckboxProp = {
                id: 'app_notification_grouping',
                value:
                    notificationPreferences.app_notification_grouping ?? false
            };
            return prop;
        });

    const [notificationSounds, setNotificationSounds] = useState<CheckboxProp>(
        () => {
            const prop: CheckboxProp = {
                id: 'app_notification_sounds',
                value: notificationPreferences.app_notification_sounds ?? false
            };
            return prop;
        }
    );

    const [infoApp, setInfoApp] = useState<CheckboxProp>(() => {
        const prop: CheckboxProp = {
            id: 'info.app',
            name: 'System',
            value: notificationPreferences.info?.app ?? false
        };
        return prop;
    });

    const [infoEmail, setInfoEmail] = useState<CheckboxProp>(() => {
        const prop: CheckboxProp = {
            id: 'info.email',
            name: 'Email',
            value: notificationPreferences.info?.email ?? false
        };
        return prop;
    });

    const handleOptionChanges = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const change: CheckboxProp = {
            id: event.target.id,
            value: event.target.checked
        };

        switch (event.target.id) {
            case 'action.app':
                change.name = 'System';
                setActionApp(change);
                notificationPreferences.action!.app = change.value;
                break;
            case 'action.email':
                change.name = 'Email';
                setActionEmail(change);
                notificationPreferences.action!.email = change.value;
                break;
            case 'alert.app':
                change.name = 'System';
                setAlertApp(change);
                notificationPreferences.alert!.app = change.value;
                break;
            case 'alert.email':
                change.name = 'Email';
                setAlertEmail(change);
                notificationPreferences.alert!.email = change.value;
                break;
            case 'app_notification_grouping':
                setNotificationGrouping(change);
                notificationPreferences.app_notification_grouping =
                    change.value;
                break;
            case 'app_notification_sounds':
                setNotificationSounds(change);
                notificationPreferences.app_notification_sounds = change.value;
                break;
            case 'info.app':
                change.name = 'System';
                setInfoApp(change);
                notificationPreferences.info!.app = change.value;
                break;
            case 'info.email':
                change.name = 'Email';
                setInfoEmail(change);
                notificationPreferences.info!.email = change.value;
                break;
            default:
                console.error(`Unknown target id: ${event.target.id}`);
                return;
        }

        const currentUser: UserBase = JSON.parse(
            localStorage.getItem('user') ?? ''
        ).user;

        currentUser.notification_preferences = notificationPreferences;
        localStorage.setItem('user', JSON.stringify({ user: currentUser }));

        updateNotificationPreferences({
            data: notificationPreferences
        });
    };

    return (
        <SettingsPageContainer>
            <SubNavPageHeader pageTitle="Notifications"></SubNavPageHeader>
            <ul className="flex flex-col w-full p-ncc-padding-24">
                <SettingItem
                    setting="Notification Groups"
                    text="Do you want to group the notifications in different sections?"
                    options={[notificationGrouping]}
                    changeHandler={handleOptionChanges}
                />
                <SettingItem
                    setting="Sound"
                    text="Play a sound for notifications"
                    options={[notificationSounds]}
                    changeHandler={handleOptionChanges}
                />
                <SettingItem
                    setting="Updates"
                    text="These are the notifications about the system updates"
                    options={[infoApp, infoEmail]}
                    changeHandler={handleOptionChanges}
                />
                <SettingItem
                    setting="Alerts"
                    text="These are the alerts in the system"
                    options={[alertApp, alertEmail]}
                    changeHandler={handleOptionChanges}
                />
                <SettingItem
                    setting="User Actions"
                    text="These are the notifications that come from the user actions"
                    options={[actionApp, actionEmail]}
                    changeHandler={handleOptionChanges}
                />
            </ul>
        </SettingsPageContainer>
    );
}

export default NotificationsPage;
