import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    IconDefinition,
    faCheck,
    faPenToSquare,
    faTimer
} from '@fortawesome/pro-regular-svg-icons';
import { useContext } from 'react';
import dayjs from 'dayjs';

import { ScheduledNotification } from 'core/api/contracts/contract.types';
import GlobalContext from './context/global-context';

interface NotificationPillProps {
    notification: ScheduledNotification;
}

const NotificationPill: React.FC<NotificationPillProps> = ({
    notification
}) => {
    const {
        setDaySelected,
        setSelectedNotification,
        setShowFormPane,
        setTimeSelected
    } = useContext(GlobalContext);

    const getBackgroundClass = () => {
        if (notification.sent_at) {
            return 'w-[100%] h-[22px] px-2 pt-1 pb-1 bg-[#e5faee] rounded-[18px] flex-col justify-center inline-flex';
        } else {
            return notification.draft
                ? 'w-[100%] h-[22px] px-2 pt-1 pb-1 bg-[#e8ecf2] rounded-[18px] flex-col justify-center inline-flex'
                : 'w-[100%] h-[22px] px-2 pt-1 pb-1 bg-[#e5eefa] rounded-[18px] flex-col justify-center inline-flex';
        }
    };

    const getColourClass = () => {
        if (notification.sent_at) {
            return 'truncate text-[12px] leading-[13px] text-[#008738]';
        } else {
            return notification.draft
                ? 'truncate text-[12px] leading-[13px] text-[#2a333c]'
                : 'truncate text-[12px] leading-[13px] text-[#0159d3]';
        }
    };

    function getNotificationIcon(): IconDefinition {
        if (notification.sent_at) return faCheck;
        return notification.draft ? faPenToSquare : faTimer;
    }

    function viewNotification() {
        const day = dayjs(notification.to_send_at);
        setDaySelected(day.format('YYYY-MM-DD'));
        setTimeSelected(day.format('HH:MM'));
        setSelectedNotification(notification);
        setShowFormPane(true);
    }

    return (
        <div
            key={notification.uuid}
            onClick={viewNotification}
            className={getBackgroundClass()}
        >
            <div className={getColourClass()}>
                <FontAwesomeIcon icon={getNotificationIcon()} />
                &nbsp;
                {notification.subject}
            </div>
        </div>
    );
};

export default NotificationPill;
