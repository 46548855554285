import { FC, forwardRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';

interface InputProps {
    className?;
    disabled?;
    error;
    label: string;
    name: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder?;
    required?;
    type;
    value;
}

const inputClasses =
    'bg-ncc-white border border-utilisation-border-color-main rounded-lg inline-flex gap-1.5 h-ncc-input-height items-center px-4 text-ncc-grey-100 text-sm transition-all focus-within:border-utilisation-accent-blue';
const labelClasses =
    'flex flex-col text-[12px] text-utilisation-light-blue flex-grow ';

const Input: FC<InputProps> = (
    {
        className,
        disabled,
        error,
        label,
        name,
        onChange,
        placeholder,
        type,
        value
    },
    ref
) => {
    return (
        <div>
            <label htmlFor={name} className={labelClasses}>
                {label}
                <input
                    ref={ref}
                    name={name}
                    onChange={onChange}
                    className={inputClasses}
                    value={value}
                    disabled={disabled}
                    placeholder={placeholder}
                    type={type}
                />
            </label>

            {error && error.message ? (
                <span className="text-utilisation-error">
                    <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        className="mr-1"
                    />
                    {error.message}
                </span>
            ) : null}
        </div>
    );
};

export default forwardRef(Input);
export { inputClasses, labelClasses};
