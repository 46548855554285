import { Outlet } from 'react-router-dom';
import SettingsNavigation from './navigation/settings-navigation';

const SettingsHolderPage = ({ collapsed }) => {
    return (
        <>
            <SettingsNavigation collapsed={collapsed}></SettingsNavigation>
            <Outlet />
        </>
    );
};

export default SettingsHolderPage;
