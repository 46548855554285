import { AxiosResponse } from 'axios';
import { MutationOptions, useMutation, useQueryClient } from 'react-query';

import { AppController } from 'core/api/contracts/app.contract';
import { Notification } from 'core/api/contracts/contract.types';
import interceptors from 'core/api/interceptors';
import securityWorker from 'core/api/securityWorker';

interface ICreateNotification {
    body: string;
    draft: boolean;
    notification_type: string;
    subject: string;
    to_send_at: number;
}

function useCreateNotification<TError = unknown, TContext = unknown>(
    options?: Omit<
        MutationOptions<
            AxiosResponse<Notification>,
            ReactQueryError<TError>,
            ICreateNotification,
            TContext
        >,
        'mutationFn'
    >
) {
    const queryClient = useQueryClient();

    return useMutation(
        [],
        async (data) => {
            const controller = new AppController({
                interceptors,
                securityWorker
            });

            return controller.adminPostNotification(data);
        },
        {
            ...options,
            onSettled: (data, error, variables, context) => {
                options?.onSettled?.(data, error, variables, context);
                queryClient.invalidateQueries(['notifications']);
            }
        }
    );
}

export default useCreateNotification;
export type { ICreateNotification };
