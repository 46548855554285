import * as yup from 'yup';

import { Contract, DayRate, UserBase } from 'core/api/contracts/contract.types';
import { FormEventHandler, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toastMessage } from 'helpers/helpers';
import { yupResolver } from '@hookform/resolvers/yup';
import AccountInformation from '../account-information/account-information';
import JobAndRoleInformation from '../job-and-role-information/job-and-role-information';
import PersonalInformation from '../personal-information/personal-information';
import UserDetailsActions from './user-details-actions';
import moment from 'moment';
import useCreateContract from 'core/api/hooks/admin/use-create-contract';
import useEditUserDetails from 'core/api/hooks/admin/use-edit-user-details';
import useUpdateContract from 'core/api/hooks/admin/use-update-contract';

export type RolesType = {
    label: string;
    name;
    value: string;
};

type UserDetailsParams = { data: UserBase };

type UserDetailsFormValues = Omit<
    UserDetailsParams['data'],
    'day_rate' | 'contracted_hours' | 'roles'
> & {
    contracted_days: number | undefined;
    contracted_hours: number | undefined;
    day_rate;
    loading: boolean;
    roles;
};

export type UserData = {
    data: UserBase;
    id: string;
};

interface UserDetailsFormProps extends UserDetailsFormValues {
    contract_uuid: string | undefined;
    day_rate: DayRate;
    id: string;
}

const columnCssClasses = 'flex flex-col gap-3.5';
const sectionStyles =
    'border-b-[1px] border-utilisation-border-color-main py-[24px]';

function UserDetailsForm({
    contract_uuid,
    contracted_days,
    contracted_hours,
    day_rate,
    deactivated_at,
    family_name,
    given_name,
    id,
    line_manager,
    loading,
    roles,
    username
}) {
    const schema = yup.object({
        contracted_days: yup
            .string()
            .min(1, 'Please enter a value')
            .nullable()
            .required(),
        contracted_hours: yup
            .string()
            .min(1, 'Please enter a value')
            .nullable()
            .required(),
        created_at: yup.string().nullable(),
        day_rate: yup.object(),
        deactivated_at: yup.string().nullable(),
        family_name: yup.string(),
        given_name: yup.string(),
        roles: yup.array().nullable(),
        username: yup.string()
    });

    const {
        formState,
        handleSubmit: userFormHandleSubmit,
        setError,
        setValue,
        ...methods
    } = useForm<UserDetailsFormValues>({
        defaultValues: {
            contracted_days: contracted_days,
            contracted_hours: contracted_hours,
            created_at:
                moment(day_rate?.created_at).format('YYYY-MM-DD') || undefined,
            day_rate: {
                label: day_rate?.name,
                value: day_rate?.uuid
            },
            deactivated_at: deactivated_at || null,
            family_name: family_name || '',
            given_name: given_name || '',
            line_manager: line_manager || null,
            roles: roles || undefined,
            username: username || ''
        },
        mode: 'onChange',
        resetOptions: {},
        resolver: yupResolver(schema)
    });

    // const values = methods.watch();

    const { mutate: putModifiedUserData } = useEditUserDetails();
    const { mutate: putContractData } = useUpdateContract();
    const { mutate: createContractData } = useCreateContract();

    const submit = useCallback(
        (formData) => {
            const { contracted_days, contracted_hours, ...rest } = formData;

            const data: UserBase = {
                ...rest,
                line_manager_uuid: formData.line_manager
                    ? formData.line_manager.value
                    : null,
                roles: formData.roles.map((role) => role['name'].toUpperCase())
            };

            const contractData: Contract = {
                began_at: day_rate
                    ? moment(day_rate?.created_at).format('YYYY-MM-DD')
                    : '1971-01-01',
                contracted_days: formData.contracted_days,
                contracted_hours: formData.contracted_hours,
                day_rate_uuid: formData.day_rate?.value,
                user_uuid: id
            };

            putModifiedUserData({ data, id });

            if (contract_uuid === undefined) {
                toastMessage('Creating contract', 'info');
                return createContractData({ contractData });
            } else {
                return putContractData({ contractData, contract_uuid });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [roles, username, id]
    );

    const preventFormDefault = useCallback<FormEventHandler<HTMLFormElement>>(
        (event) => {
            event.preventDefault();
        },
        []
    );

    const showError = (data) => {
        console.log('Errors in the showError:', data);
    };

    const triggerSubmit = useCallback(() => {
        userFormHandleSubmit(submit, showError)();
    }, [userFormHandleSubmit, submit]);

    return (
        <div className="h-full flex flex-col gap-4">
            <FormProvider
                {...methods}
                setValue={setValue}
                setError={setError}
                formState={formState}
                handleSubmit={userFormHandleSubmit}
            >
                <form
                    className="grid grid-cols-1"
                    onSubmit={preventFormDefault}
                >
                    <UserDetailsActions
                        trigger={triggerSubmit}
                        usersName={`${given_name} ${family_name}`}
                    ></UserDetailsActions>

                    <div className={sectionStyles}>
                        <AccountInformation
                            className={columnCssClasses}
                            username={username}
                            line_manager={line_manager}
                            loading={loading}
                        ></AccountInformation>
                    </div>
                    <div className={sectionStyles}>
                        <PersonalInformation
                            className={columnCssClasses}
                            user={`${given_name} ${family_name}`}
                            loading={loading}
                        ></PersonalInformation>
                    </div>
                    <div className={sectionStyles}>
                        <JobAndRoleInformation
                            className={columnCssClasses}
                            data={{
                                contracted_days: contracted_days,
                                contracted_hours: contracted_hours,
                                day_rate: day_rate,
                                roles: roles
                            }}
                            loading={loading}
                        ></JobAndRoleInformation>
                    </div>
                    {/* <div className={sectionStyles}>
                        <AdminAndSettings
                            className={columnCssClasses}
                            deactivated_at={deactivated_at}
                            loading={loading}
                        ></AdminAndSettings>
                    </div> */}
                </form>
            </FormProvider>
        </div>
    );
}

export default UserDetailsForm;
export type { UserDetailsFormProps };
