import {
    FontAwesomeIcon,
    FontAwesomeIconProps
} from '@fortawesome/react-fontawesome';

interface IFontIconProps extends FontAwesomeIconProps {
    className?: string;
}

function FontIcon({ className, icon }: IFontIconProps) {
    return (
        <FontAwesomeIcon icon={icon} className={className}></FontAwesomeIcon>
    );
}

export default FontIcon;
