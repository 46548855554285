// This List component accepts an array of items
// and a renderItem function to display each item.
// You can customize it by passing different data types and rendering functions.

import { twMerge } from 'tailwind-merge';
import classNames from 'classnames';

interface IList<T> {
    customStyleClasses?: string;
    hover?: boolean;
    items: T[] | undefined;
    renderItem: (item: T) => React.ReactNode;
}

// Create a generic List component
const List = <T extends object>({
    customStyleClasses,
    hover,
    items,
    renderItem
}: IList<T>) => {
    const baseClasses = classNames(
        'py-ncc-padding-8 px-ncc-padding-16 w-full cursor-pointer relative',
        {
            'hover:bg-ncc-grey-10': hover
        }
    );

    const mergedClasses = twMerge([baseClasses, customStyleClasses]);

    return (
        <ul>
            {items ? (
                items?.map((item, i) => (
                    <li className={mergedClasses} key={i}>
                        {renderItem(item)}
                    </li>
                ))
            ) : (
                <li>Nothing to display!</li>
            )}
        </ul>
    );
};

export default List;
export type { IList };
