import { UserBase } from 'core/api/contracts/contract.types';
import ControlledInput from 'components/form/components/controlled-input';

type PersonalInformationProps = {
    className: string;
    loading: boolean;
    user: UserBase;
};

const PersonalInformation = ({ className, loading, user }) => {
    return (
        <>
            <h2 className="font-bold pb-[20px]">Personal Information</h2>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <div className={className}>
                    <div className="grid grid-cols-4 gap-3.5">
                        <ControlledInput
                            readOnly={true}
                            name="given_name"
                            label="First name"
                            type="text"
                        ></ControlledInput>

                        <ControlledInput
                            readOnly={true}
                            name="family_name"
                            label="Last name"
                            type="text"
                        ></ControlledInput>
                    </div>
                </div>
            )}
        </>
    );
};

export default PersonalInformation;
export type { PersonalInformationProps };
