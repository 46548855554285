import {
    ForwardedRef,
    PropsWithChildren,
    ReactNode,
    forwardRef,
    useMemo
} from 'react';
import { TailwindProps, tailwindClasses } from '@ncc-frontend/core';
import classNames from 'classnames';

interface IAlertProps extends TailwindProps {
    children?: ReactNode;
    variant?: 'success' | 'warning' | 'info' | 'error';
}

function Alert(
    {
        children,
        className,
        tailwindStyle,
        variant = 'success',
        ...restProps
    }: PropsWithChildren<IAlertProps>,
    ref: ForwardedRef<HTMLDivElement>
) {
    const cssClasses = useMemo(
        () =>
            classNames(
                className,
                tailwindClasses(
                    {
                        display: 'inline-flex',
                        gap: 'gap-1.5',
                        whiteSpace: 'whitespace-nowrap'
                    },
                    tailwindStyle
                )
            ),
        [className, tailwindStyle]
    );

    return (
        <div {...restProps} ref={ref} className={cssClasses}>
            {children}
        </div>
    );
}

export default forwardRef(Alert);
export type { IAlertProps };
