import { AxiosResponse } from 'axios';
import { MutationOptions, useMutation, useQueryClient } from 'react-query';

import { AppController } from 'core/api/contracts/app.contract';
import { Notification } from 'core/api/contracts/contract.types';
import interceptors from 'core/api/interceptors';
import securityWorker from 'core/api/securityWorker';

type IUpdateScheduledNotification = {
    data: Notification;
    notification_uuid;
};

function useUpdateNotification<TError = unknown, TContext = unknown>(
    options?: Omit<
        MutationOptions<
            AxiosResponse<Notification>,
            ReactQueryError<TError>,
            IUpdateScheduledNotification,
            TContext
        >,
        'mutationFn'
    >
) {
    const queryClient = useQueryClient();

    return useMutation(
        [],
        async (update: IUpdateScheduledNotification) => {
            const controller = new AppController({
                interceptors,
                securityWorker
            });

            return controller.updateScheduledNotification(
                update.notification_uuid,
                update.data
            );
        },
        {
            ...options,
            onSettled: (data, error, variables, context) => {
                options?.onSettled?.(data, error, variables, context);
                queryClient.invalidateQueries({ queryKey: ['notifications'] });
            }
        }
    );
}

export default useUpdateNotification;
