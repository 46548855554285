import { useCallback } from 'react';

import { AppController } from '../../contracts/app.contract';
import interceptors from 'core/api/interceptors';
import securityWorker from '../../securityWorker';
import useQuery from 'core/api/react-query/useQuery';

function useNotificationsDataHook({ query }) {
    if (query) {
        query = query.query;
    }

    const request = useCallback(async () => {
        const controller = new AppController({
            interceptors,
            securityWorker
        });

        const response = await controller.getNotifications(query);

        return response.data;
    }, [query]);

    return useQuery(['notifications', 'list', query], request);
}

export default useNotificationsDataHook;
