import { MutationOptions, useMutation } from 'react-query';

import { AppController } from 'core/api/contracts/app.contract';
import { AxiosResponse } from 'axios';
import interceptors from 'core/api/interceptors';

interface obj {
    end_date: string;
    product_uuids: string[] | undefined;
    start_date: string;
}

interface AllocationDownloadParams extends obj {}

function useDownloadAllocationsCSV<TError = unknown, TContext = unknown>(
    options?: Omit<
        MutationOptions<
            AxiosResponse<obj>,
            ReactQueryError<TError>,
            AllocationDownloadParams,
            TContext
        >,
        'mutationFn'
    >
) {
    return useMutation<
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        any,
        ReactQueryError<TError>,
        AllocationDownloadParams,
        TContext
    >(async (query) => {
        const controller = new AppController({
            interceptors
        });

        return controller.appReportAllocations(query);
    }, options);
}

export default useDownloadAllocationsCSV;
export type { AllocationDownloadParams };
