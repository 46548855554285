import { useCallback } from 'react';
import { useQuery } from 'react-query';

import { AppController } from 'core/api/contracts/app.contract';
import interceptors from 'core/api/interceptors';
import securityWorker from 'core/api/securityWorker';

function useAdminNotifications({ query }) {
    const request = useCallback(async () => {
        const controller = new AppController({
            interceptors,
            securityWorker
        });
        const response = await controller.getAdminNotifications(query);
        return response.data;
    }, [query]);

    return useQuery(['notifications', 'list', query], request);
}

export default useAdminNotifications;
