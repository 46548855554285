import { ApiConfig } from './contracts/http-client.contract';
import { AxiosRequestConfig } from 'axios';

function securityWorker():
    | Promise<AxiosRequestConfig | void>
    | AxiosRequestConfig
    | void {

    return {};
}

export default securityWorker as ApiConfig<null>['securityWorker'];
