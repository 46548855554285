import { AppController } from '../../contracts/app.contract';
import { MutationOptions, useMutation, useQueryClient } from 'react-query';
import interceptors from '../../interceptors';
import securityWorker from '../../securityWorker';

import { ActivityGroup } from '../../contracts/contract.types';
import { AxiosResponse } from 'axios';

type AllocationPostType = {
    body: {
        allocations:
        {
            contract_uuid: string;
            listening_post?: ActivityGroup | undefined;
            percentage: number;
            product_uuid: string;

        }[],
        user_uuid: string,
        week: string
    }
}

function usePostAllocation<TError = unknown, TContext = unknown>(
    options?: Omit<
        MutationOptions<
            AxiosResponse<void, unknown>,
            ReactQueryError<TError>,
            AllocationPostType,
            TContext
        >,
        'mutationFn'
    >
) {
    const queryClient = useQueryClient();

    return useMutation<
        AxiosResponse<void, unknown>,
        ReactQueryError<TError>,
        AllocationPostType,
        TContext
    >(
        [],
        async ({ body }) => {
            const controller = new AppController({
                interceptors,
                securityWorker
            });

            return controller.setAdminAllocations(body);
        },
        {
            ...options,
            onSettled: (data, error, variables, context) => {
                options?.onSettled?.(data, error, variables, context);
                queryClient.invalidateQueries(['teams']);
            }
        }
    );
}

export default usePostAllocation;
