import {
    Button as CoreButton,
    ButtonProps as CoreButtonProps,
    TailwindStyle
} from '@ncc-frontend/core';
import {
    ForwardedRef,
    PropsWithChildren,
    forwardRef,
    memo,
    useMemo
} from 'react';
import { merge } from 'lodash';

interface ButtonProps extends Omit<CoreButtonProps, 'variant'> {
    variant?:
        | 'primary'
        | 'secondary'
        | 'secondary-special'
        | 'tertiary'
        | 'danger'
        | 'warning';
}

const getVariantStyle = (variant: string): TailwindStyle => {
    const styles: { [key: string]: TailwindStyle } = {
        danger: {
            background: 'bg-utilisation-red-100',
            textColor: 'text-ncc-white',
            variant: {
                active: {
                    background: 'active:bg-utilisation-red-100',
                    textColor: 'active:!text-ncc-white'
                },
                disabled: {
                    background: 'disabled:bg-ncc-grey-20',
                    textColor: 'disabled:!text-ncc-grey-50'
                },
                hover: {
                    background: 'hover:bg-utilisation-red-100',
                    textColor: 'hover:text-ncc-white'
                }
            }
        },
        primary: {
            background: 'bg-ncc-group-purple-100',
            border: 'border',
            borderColor: 'border-ncc-group-purple-100',
            height: 'h-ncc-btn-height',
            textColor: 'text-white',
            textSize: 'text-p',
            textWeight: 'font-Medium',
            variant: {
                active: {
                    background: 'active:bg-ncc-group-purple-110',
                    borderColor: 'active:border-ncc-group-purple-110',
                    textColor: 'active:text-white'
                },
                disabled: {
                    background: 'disabled:bg-ncc-grey-20',
                    borderColor: 'disabled:border-ncc-grey-20',
                    textColor: 'disabled:text-ncc-grey-70'
                },
                hover: {
                    background: 'ncc-group-purple-110',
                    borderColor: 'hover:border-ncc-group-purple-110',
                    textColor: 'hover:text-white'
                }
            }
        },
        secondary: {
            background: 'bg-ncc-white',
            border: 'border',
            borderColor: 'border-ncc-grey-40',
            height: 'h-ncc-btn-height',
            textColor: 'text-ncc-grey-90',
            textSize: 'text-p',
            textWeight: 'font-Medium',
            variant: {
                active: {
                    borderColor: 'active:border-ncc-grey-30',
                    textColor: 'active:text-ncc-grey-70'
                },
                disabled: {
                    background: 'disabled:bg-ncc-grey-20',
                    borderColor: 'disabled:border-ncc-grey-20',
                    textColor: 'disabled:text-ncc-grey-70'
                },
                hover: {
                    borderColor: 'hover:border-ncc-grey-50',
                    textColor: 'hover:text-ncc-grey-100'
                }
            }
        },
        'secondary-special': {
            background: 'bg-ncc-white',
            border: 'border',
            borderColor: 'border-ncc-grey-40',
            height: 'h-ncc-btn-height',
            textColor: 'text-ncc-portal-blue',
            textSize: 'text-p',
            textWeight: 'font-Medium',
            variant: {
                active: {
                    borderColor: 'active:border-ncc-grey-20',
                    textColor: 'active:text-ncc-portal-blue'
                },
                disabled: {
                    background: 'disabled:bg-ncc-grey-20',
                    borderColor: 'disabled:border-ncc-grey-20',
                    textColor: 'disabled:text-ncc-grey-60'
                },
                hover: {
                    borderColor: 'hover:border-ncc-grey-20',
                    textColor: 'hover:text-ncc-group-purple-90'
                }
            }
        },
        tertiary: {
            background: 'bg-transparent',
            height: 'h-ncc-btn-height',
            textColor: 'text-ncc-grey-90',
            variant: {
                active: {
                    background: 'active:bg-transparent',
                    textColor: 'active:text-ncc-grey-70'
                },
                disabled: {
                    background: 'disabled:bg-transparent',
                    textColor: 'disabled:text-ncc-grey-50'
                },
                hover: {
                    background: 'hover:bg-transparent',
                    textColor: 'hover:text-ncc-grey-50'
                }
            }
        },
        warning: {
            background: 'bg-utilisation-yellow-20',
            textColor: 'text-utilisation-yellow-150',
            variant: {
                active: {
                    background: 'active:bg-utilisation-yellow-10',
                    textColor: 'active:!text-utilisation-yellow-100'
                },
                disabled: {
                    background: 'disabled:bg-ncc-grey-20',
                    textColor: 'disabled:!text-ncc-grey-50'
                },
                hover: {
                    background: 'hover:bg-utilisation-yellow-100',
                    textColor: 'hover:text-ncc-white'
                }
            }
        }
    };
    return styles[variant] || {};
};

function Button(
    {
        children,
        disabled,
        tailwindStyle,
        variant = 'primary',
        ...restProps
    }: PropsWithChildren<ButtonProps>,
    ref: ForwardedRef<HTMLButtonElement>
) {
    const mergedTailwindStyle = useMemo(() => {
        const baseStyle: TailwindStyle = {
            border: '',
            borderRadius: 'rounded-lg',
            textWeight: 'font-medium',
            truncate: ''
        };
        const variantStyle = getVariantStyle(variant);
        return merge(baseStyle, variantStyle, tailwindStyle);
    }, [tailwindStyle, variant]);

    return (
        <CoreButton
            {...restProps}
            ref={ref}
            tailwindStyle={mergedTailwindStyle}
            disabled={disabled}
        >
            {!!children && (
                <span className="truncate flex items-center gap-2.5">
                    {children}
                </span>
            )}
        </CoreButton>
    );
}

export default memo(forwardRef(Button));
export type { ButtonProps };
