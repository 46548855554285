import {
    Controller,
    FormProvider,
    SubmitHandler,
    useForm
} from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import dayjs from 'dayjs';

import {
    inputClasses,
    labelClasses
} from 'components/form/elements/input-element';
import { twMerge } from 'tailwind-merge';
import Button from 'components/button/button';
import Editor from 'components/editor/editor';
import GlobalContext from 'components/calendar/context/global-context';
import useCreateNotification from 'core/api/hooks/notifications/use-create-notification';
import useDeleteNotification from 'core/api/hooks/notifications/use-delete-notification';
import useUpdateAdminNotification from 'core/api/hooks/notifications/use-update-admin-notification';

type NotificationFormValues = {
    body: string;
    date: string;
    notification_type: string;
    send_type: string;
    subject: string;
    time: string;
    uuid: string;
};

const NotificationForm: React.FC = () => {
    const {
        daySelected,
        notificationUnsent,
        selectedNotification,
        setShowFormPane,
        timeSelected
    } = useContext(GlobalContext);

    const [dateFieldError, setDateFieldError] = useState(false);
    const [formDisabled, setFormDisabled] = useState(false);
    const [showDateFields, setShowDateFields] = useState(false);

    const maxBodyLength = 2048;

    const form = useForm<NotificationFormValues>({
        defaultValues: {
            body: '',
            send_type: 'NOW',
            subject: '',
            uuid: ''
        },
        mode: 'onTouched'
    });

    const {
        control,
        formState: { errors },
        getValues,
        handleSubmit,
        register,
        reset,
        setValue
    } = form;

    const { mutate: createNotification } = useCreateNotification();
    const { mutate: deleteNotification } = useDeleteNotification();
    const { mutate: updateNotification } = useUpdateAdminNotification();

    function handleReset() {
        setShowFormPane(false);
        setDateFieldError(false);
        reset();
    }

    const onCreate: SubmitHandler<NotificationFormValues> = async (data) => {
        try {
            if (data.body.length > maxBodyLength) {
                throw new Error('Maximum body length exceeded');
            }

            const scheduledDateTime = dayjs(`${data.date}T${data.time}`);
            let send_at: number = scheduledDateTime.unix();

            if (data.send_type === 'NOW') {
                send_at = Math.trunc(new Date().getTime() / 1000);
            } else {
                if (scheduledDateTime.isBefore(dayjs())) {
                    setDateFieldError(true);

                    throw new Error('Scheduled date must be in the future');
                }
            }

            const createData = {
                body: data.body,
                draft: data.send_type === 'DRAFT' ? true : false,
                notification_type: data.notification_type,
                subject: data.subject,
                to_send_at: send_at
            };

            createNotification(createData);
            handleReset();
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const onDelete = async (uuid: string) => {
        try {
            deleteNotification({ query: { uuid } });
            handleReset();
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const onUpdate: SubmitHandler<NotificationFormValues> = async (data) => {
        try {
            if (data.body.length > maxBodyLength) {
                throw new Error('Maximum body length exceeded');
            }

            let send_at: number = dayjs(`${data.date}T${data.time}`).unix();
            if (data.send_type === 'NOW') {
                send_at = Math.trunc(new Date().getTime() / 1000);
            }

            const updateData = {
                body: data.body,
                draft: data.send_type === 'DRAFT' ? true : false,
                is_read: false,
                notification_type: data.notification_type,
                subject: data.subject,
                to_send_at: send_at,
                uuid: selectedNotification.uuid
            };

            updateNotification({
                data: updateData,
                notification_uuid: selectedNotification.uuid
            });
            handleReset();
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const onSubmit: SubmitHandler<NotificationFormValues> = async (data) => {
        if (selectedNotification) {
            return onUpdate(data);
        } else {
            return onCreate(data);
        }
    };

    const type_options = [
        { label: 'Action', value: 'ACTION' },
        { label: 'Alert', value: 'ALERT' },
        { label: 'Info', value: 'INFO' }
    ];

    const send_options = [
        { label: 'Send Immediately', value: 'NOW' },
        { label: 'Schedule', value: 'SCHEDULE' },
        { label: 'Save as Draft', value: 'DRAFT' }
    ];

    const styles = twMerge([inputClasses, 'w-full']);

    useEffect(() => {
        try {
            if (selectedNotification) {
                setValue('body', selectedNotification.body);
                setValue('subject', selectedNotification.subject);
                setValue(
                    'notification_type',
                    selectedNotification.notification_type
                );
                setFormDisabled(selectedNotification.sent_at ? true : false);

                if (selectedNotification.draft) {
                    setValue('send_type', 'DRAFT');
                } else {
                    setValue('send_type', 'SCHEDULE');
                }

                const to_send_at = dayjs(selectedNotification.to_send_at);

                setValue('date', to_send_at.format('YYYY-MM-DD'));
                setValue('time', to_send_at.format('HH:MM'));
                setShowDateFields(true);
            } else {
                if (daySelected) setValue('date', daySelected);
                if (timeSelected) setValue('time', timeSelected);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }, [daySelected, selectedNotification, setValue, timeSelected]);

    return (
        <FormProvider {...form}>
            <form
                onChange={() => {
                    setShowDateFields(getValues('send_type') !== 'NOW');
                }}
                onSubmit={handleSubmit(onSubmit)}
                className="flex flex-col gap-6 text-left"
            >
                <fieldset disabled={formDisabled}>
                    <div>
                        {send_options.map((item, i) => {
                            return (
                                <div key={Math.random()}>
                                    <input
                                        type="radio"
                                        value={item.value}
                                        {...register('send_type', {
                                            required: true
                                        })}
                                    />
                                    <label>&nbsp;{item.label}</label>
                                </div>
                            );
                        })}
                        {errors.send_type && (
                            <span className="text-red-800">
                                This field is required
                            </span>
                        )}
                    </div>
                    <div className="mt-2">
                        <label htmlFor="subject" className={labelClasses}>
                            Title
                        </label>
                        <input
                            id="subject"
                            className={styles}
                            {...register('subject', { required: true })}
                        />
                        {errors.subject && (
                            <span className="text-red-800">
                                This field is required
                            </span>
                        )}
                    </div>
                    <div className="mt-2">
                        <label htmlFor="body" className={labelClasses}>
                            Body
                        </label>
                        <Controller
                            control={control}
                            name="body"
                            render={({ field }) => (
                                <Editor
                                    field={field}
                                    maxLength={maxBodyLength}
                                    readOnly={formDisabled}
                                ></Editor>
                            )}
                            rules={{ required: true }}
                        />
                        {errors.body && (
                            <span className="text-red-800">
                                This field is required
                            </span>
                        )}
                    </div>
                    <div className="py-2">
                        <label
                            htmlFor="notification_type"
                            className={labelClasses}
                        >
                            Type of notification
                        </label>
                        <Controller
                            control={control}
                            name="notification_type"
                            render={({ field }) => (
                                <Select
                                    onChange={(val) =>
                                        field.onChange(val?.value)
                                    }
                                    options={type_options}
                                    placeholder="Select a notification type"
                                    value={type_options.find(
                                        (c) => c.value === field.value
                                    )}
                                />
                            )}
                            rules={{ required: true }}
                        />
                        {errors.notification_type && (
                            <span className="text-red-800">
                                This field is required
                            </span>
                        )}
                    </div>
                    <div className="flex justify-between mt-2">
                        {showDateFields && (
                            <div className="grid gap-6 grid-cols-2">
                                <div>
                                    <label
                                        htmlFor="to_send_at"
                                        className={labelClasses}
                                    >
                                        Date of notification
                                    </label>
                                    <input
                                        className={styles}
                                        type="date"
                                        {...register('date', {
                                            validate: (value, formValues) => {
                                                const validDate =
                                                    value.match(
                                                        /\d{4}-\d{2}-\d{2}/
                                                    );
                                                return (
                                                    formValues.send_type ===
                                                        'NOW' ||
                                                    validDate !== null
                                                );
                                            }
                                        })}
                                    />
                                    {errors.date && (
                                        <span className="text-red-800">
                                            This field is required
                                        </span>
                                    )}
                                </div>
                                <div>
                                    <label
                                        htmlFor="to_send_at"
                                        className={labelClasses}
                                    >
                                        Time of notification
                                    </label>
                                    <input
                                        className={styles}
                                        type="time"
                                        {...register('time', {
                                            validate: (value, formValues) => {
                                                const validDate =
                                                    value.match(/\d{2}:\d{2}/);
                                                return (
                                                    formValues.send_type ===
                                                        'NOW' ||
                                                    validDate !== null
                                                );
                                            }
                                        })}
                                    />
                                    {errors.time && (
                                        <span className="text-red-800">
                                            This field is required
                                        </span>
                                    )}
                                </div>
                                {dateFieldError && (
                                    <span className="text-red-800 col-span-2">
                                        Scheduled date must be in the future
                                    </span>
                                )}
                            </div>
                        )}
                    </div>
                    <div className="mt-8">
                        {!selectedNotification && (
                            <div className="flex justify-between">
                                <Button
                                    variant="secondary"
                                    onClick={(evt) => {
                                        evt.preventDefault();
                                        reset();
                                    }}
                                >
                                    Clear All
                                </Button>
                                <Button variant="primary">
                                    Create Notification
                                </Button>
                            </div>
                        )}
                        {notificationUnsent() && (
                            <div className="flex justify-between">
                                <Button
                                    variant="secondary"
                                    onClick={(evt) => {
                                        evt.preventDefault();
                                        onDelete(selectedNotification.uuid);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faTrash} />
                                    Delete
                                </Button>
                                <Button variant="primary">
                                    Save Notification
                                </Button>
                            </div>
                        )}
                    </div>
                </fieldset>
            </form>
        </FormProvider>
    );
};

export default NotificationForm;
