import { FC, ReactNode, useEffect, useState } from 'react';
import dayjs from 'dayjs';

import { ScheduledNotification } from 'core/api/contracts/contract.types';
import GlobalContext from './global-context';

interface ContextWrapperProps {
    children: ReactNode;
}

const ContextWrapper: FC<ContextWrapperProps> = (props) => {
    const [filteredNotifications, setFilteredNotifications] = useState<
        Map<string, Array<ScheduledNotification>>
    >(new Map());
    const [monthIndex, setMonthIndex] = useState<number>(new Date().getMonth());
    const [daySelected, setDaySelected] = useState<string>(
        dayjs().format('YYYY-MM-DD')
    );
    const [timeSelected, setTimeSelected] = useState<string>(
        dayjs().format('HH:MM')
    );
    const [showFormPane, setShowFormPane] = useState<boolean>(false);
    const [selectedNotification, setSelectedNotification] =
        useState<ScheduledNotification | null>(null);

    useEffect(() => {
        if (!showFormPane) {
            setSelectedNotification(null);
        }
    }, [showFormPane]);

    const notificationUnsent = () => {
        return selectedNotification && !selectedNotification.sent_at;
    };

    return (
        <GlobalContext.Provider
            value={{
                daySelected,
                filteredNotifications,
                monthIndex,
                notificationUnsent,
                selectedNotification,
                setDaySelected,
                setFilteredNotifications,
                setMonthIndex,
                setSelectedNotification,
                setShowFormPane,
                setTimeSelected,
                showFormPane,
                timeSelected
            }}
        >
            {props.children}
        </GlobalContext.Provider>
    );
};

export default ContextWrapper;
