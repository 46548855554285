import { AxiosResponse } from 'axios';
import { MutationOptions, useMutation, useQueryClient } from 'react-query';

import { CoreController } from 'core/api/contracts/core';
import { Product } from 'core/api/contracts/contract.types';
import interceptors from 'core/api/interceptors';
import securityWorker from 'core/api/securityWorker';

type CreateProductParams = {
    data: Product;
};

function useCreateProduct<TError = unknown, TContext = unknown>(
    options?: Omit<
        MutationOptions<
            AxiosResponse<Product>,
            ReactQueryError<TError>,
            CreateProductParams,
            TContext
        >,
        'mutationFn'
    >
) {
    const queryClient = useQueryClient();

    return useMutation(
        [],
        async ({ data }: CreateProductParams) => {
            const controller = new CoreController({
                interceptors,
                securityWorker
            });

            return controller.createProduct(data);
        },
        {
            ...options,
            onSettled: (data, error, variables, context) => {
                options?.onSettled?.(data, error, variables, context);
                queryClient.invalidateQueries({ queryKey: ['products'] });
            }
        }
    );
}

export default useCreateProduct;
export type { CreateProductParams };
