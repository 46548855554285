/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import { ScheduledNotification } from 'core/api/contracts/contract.types';
import React, { Dispatch, SetStateAction } from 'react';

interface GlobalContextType {
    daySelected: string;
    filteredNotifications: Map<string, Array<ScheduledNotification>>;
    monthIndex: number;
    notificationUnsent: any;
    selectedNotification: any | null;
    setDaySelected: Dispatch<SetStateAction<string>>;
    setFilteredNotifications: Dispatch<
        SetStateAction<Map<string, Array<ScheduledNotification>>>
    >;
    setMonthIndex: Dispatch<SetStateAction<number>>;
    setSelectedNotification: Dispatch<SetStateAction<any | null>>;
    setShowFormPane: Dispatch<SetStateAction<boolean>>;
    setTimeSelected: Dispatch<SetStateAction<string>>;
    showFormPane: boolean;
    timeSelected: string;
}

const GlobalContext = React.createContext<GlobalContextType>({
    daySelected: '',
    filteredNotifications: new Map(),
    monthIndex: 0,
    notificationUnsent: () => {},
    selectedNotification: null,
    setDaySelected: () => {},
    setFilteredNotifications: () => {},
    setMonthIndex: () => {},
    setSelectedNotification: () => {},
    setShowFormPane: () => {},
    setTimeSelected: () => {},
    showFormPane: false,
    timeSelected: ''
});

export default GlobalContext;
