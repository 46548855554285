import List from 'components/list/list';
import OnOutsideClick from 'common/use-outside-click';
import React, { useState } from 'react';

interface IDropdown<T> {
    options: T[];
    renderOptions;
    trigger?: React.ReactNode;
}

function Dropdown<T extends object>({
    options,
    renderOptions,
    trigger
}: IDropdown<T>) {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedItem] = useState<T | null>(null);

    return (
        <div className="dropdown">
            {trigger ? (
                <div onClick={() => setIsOpen(!isOpen)}>{trigger}</div>
            ) : (
                <button
                    className="dropdown-toggle"
                    onClick={() => setIsOpen(!isOpen)}
                    aria-haspopup="listbox"
                    aria-expanded={isOpen}
                >
                    {selectedItem ? selectedItem : 'Select an option'}
                </button>
            )}
            {isOpen && (
                <div className="absolute bg-white rounded-[12px] border border-utilisation-border-color-main right-[20px] w-[250px]">
                    <OnOutsideClick
                        children={
                            <List
                                hover={true}
                                items={options}
                                renderItem={renderOptions}
                            ></List>
                        }
                        onOutsideClick={() => setIsOpen(false)}
                    ></OnOutsideClick>
                </div>
            )}
        </div>
    );
}

export default Dropdown;
export type { IDropdown };
