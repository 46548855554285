import { NotificationsProvider } from 'ui/notifications/notifications-context';
import MainBody from './bodies/main-body';
import MainHeader from './headers/main-header';
import MainSider from './siders/main-sider';

const CoreLayout = ({ collapsed, onToggleClick }) => {
    return (
        <div className="flex h-full w-screen">
            <NotificationsProvider>
                <MainHeader
                    collapsed={collapsed}
                    onToggleClick={onToggleClick}
                ></MainHeader>
            </NotificationsProvider>
            <MainSider
                collapsed={collapsed}
                onToggleClick={onToggleClick}
            ></MainSider>
            <MainBody className="flex-1" collapsed={collapsed}></MainBody>
        </div>
    );
};

export default CoreLayout;
