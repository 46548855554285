import AdminNavigationLink from './admin-navigation-link';
import MainRouteHeading from 'ui/page-headings/main-route-heading';
import classNames from 'classnames';

const AdminNavigation = ({ collapsed }) => {
    const cssClasses = classNames(
        'w-[336px] h-[100vh] bg-ncc-grey-10 px-5 dark:bg-utilisation-page-dark fixed',
        {
            'ml-1': collapsed,
            'ml-57': !collapsed
        }
    );
    const navigationItems = [
        {
            text: 'Cost Levels',
            to: '/admin/cost-levels'
        },
        {
            text: 'Products',
            to: '/admin/products'
        },
        {
            text: 'User Management',
            to: '/admin/users'
        },
        {
            text: 'Activity Groups',
            to: '/admin/activity-groups'
        },
        {
            text: 'Notifications Management',
            to: '/admin/notifications-management'
        }
    ];

    return (
        <div className={cssClasses}>
            <MainRouteHeading
                level={1}
                title="Admin"
                shadow={false}
            ></MainRouteHeading>
            {navigationItems.map((item, i) => {
                return (
                    <AdminNavigationLink
                        key={item.text}
                        text={item.text}
                        to={item.to}
                    ></AdminNavigationLink>
                );
            })}
        </div>
    );
};

export default AdminNavigation;
