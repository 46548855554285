import {
    faChevronLeft,
    faChevronRight
} from '@fortawesome/pro-regular-svg-icons';
import { useContext } from 'react';
import FontIcon from 'components/icon/icon';
import GlobalContext from './context/global-context';
import dayjs from 'dayjs';

export default function CalendarHeader() {
    const { monthIndex, setMonthIndex } = useContext(GlobalContext);

    function handleNextMonth() {
        const todayMonth = dayjs().month();
        const newMonth = Math.min(monthIndex + 1, todayMonth + 12);
        setMonthIndex(newMonth);
    }

    function handlePrevMonth() {
        const todayMonth = dayjs().month();
        const newMonth = Math.max(monthIndex - 1, todayMonth - 12);
        setMonthIndex(newMonth);
    }

    function handleReset() {
        setMonthIndex(
            monthIndex === dayjs().month()
                ? monthIndex + Math.random()
                : dayjs().month()
        );
    }

    return (
        <header className="py-2 flex items-center mb-6">
            <h2 className="ml-4 text-xl text-gray-500 font-bold">
                {dayjs(new Date(dayjs().year(), monthIndex)).format(
                    'MMMM YYYY'
                )}
            </h2>
            <div className="ml-auto">
                <button
                    onClick={handleReset}
                    className="border rounded py-2 px-4 mr-5"
                >
                    Today
                </button>
                <button onClick={handlePrevMonth}>
                    <span className="material-icons-outlined cursor-pointer text-gray-600 mx-2">
                        <FontIcon icon={faChevronLeft} />
                    </span>
                </button>
                <button onClick={handleNextMonth}>
                    <span className="material-icons-outlined cursor-pointer text-gray-600 mx-2">
                        <FontIcon icon={faChevronRight} />
                    </span>
                </button>
            </div>
        </header>
    );
}
