import { INotificationConfiguration } from 'pages/notifications/view-configs/config.types';
import {
    RenderNotificationsOptions,
    notificationsOptions
} from 'pages/notifications/notifications-options';
import { Typography } from 'components';
import { faListDots } from '@fortawesome/pro-regular-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Dropdown from 'components/dropdown/dropdown';
import FontIcon from 'components/icon/icon';
import NotificationSystemFilterResults from './notification-system-filter-results';

interface INotificationSystem {
    configuration: INotificationConfiguration;
}

const NotificationSystem = ({ configuration }: INotificationSystem) => {
    const [activeTab, setActiveTab] = useState(configuration.tabs![0]);
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <div className="h-full">
            {configuration.heading && (
                <div className="p-ncc-padding-24 pb-ncc-padding-8 flex items-center justify-between">
                    <Typography.Header level={3} shadow={false}>
                        Notifications
                    </Typography.Header>
                    <Dropdown
                        trigger={<FontIcon icon={faListDots} />}
                        options={notificationsOptions}
                        renderOptions={RenderNotificationsOptions}
                    ></Dropdown>
                </div>
            )}
            <ul className="flex border-b-2 border-ncc-grey-10 pl-ncc-padding-24 relative">
                {configuration.tabs?.map((tab, index) => (
                    <li
                        key={index}
                        className={`tab-item cursor-pointer p-ncc-padding-12 gap-1 flex items-center text-ncc-grey-60 hover:text-utilisation-black ${
                            index === activeTab?.id
                                ? 'active border-b-4 border-solid border-utilisation-black text-utilisation-black '
                                : ''
                        }`}
                        onClick={() => {
                            setActiveTab(tab);
                            if (location.pathname.includes('notifications'))
                                navigate('/notifications');
                        }}
                    >
                        {tab.icon}
                        {tab.label}
                    </li>
                ))}
            </ul>

            <NotificationSystemFilterResults
                showViewingPanel={configuration.showViewingPanel}
                query={activeTab?.endpoint}
            ></NotificationSystemFilterResults>
        </div>
    );
};

export default NotificationSystem;
