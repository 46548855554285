/* eslint-disable */
/* tslint:disable */

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, CancelTokenSource, ResponseType } from 'axios';

// Request params definitions

export type QueryParamsType = Record<string | number, any>;
export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
    /** request body */
    body?: unknown;
    /** format of response (i.e. response.json() -> format: "json") */
    format?: ResponseType;
    /** request path */
    path: string;
    /** query params */
    query?: QueryParamsType;
    /** set parameter to `true` for call `securityWorker` for this request */
    secure?: boolean;
    /** content type of request body */
    type?: ContentType;
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>;

// Interceptors definitions

export type RequestInterceptor = {
    onFulfilled?: (value: AxiosRequestConfig) => AxiosRequestConfig | Promise<AxiosRequestConfig>;
    onRejected?: (error: any) => any;
};

export type ResponseInterceptor = {
    onFulfilled?: (value: AxiosResponse) => AxiosResponse | Promise<AxiosResponse>;
    onRejected?: (error: any) => any;
};

export type Interceptors = {
    request?: RequestInterceptor[];
    response?: ResponseInterceptor[];
};

// API Type definition

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
    format?: ResponseType;
    interceptors?: Interceptors;
    secure?: boolean;
    securityWorker?: (
        securityData: SecurityDataType | null | unknown
    ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
}

// Content Type definition

export enum ContentType {
    FormData = 'multipart/form-data',
    Json = 'application/json',
    Text = 'text/plain',
    UrlEncoded = 'application/x-www-form-urlencoded'
}

// HTTP Client class definition
export class HttpClient<SecurityDataType = unknown> {
    private instance: AxiosInstance;
    private secure?: boolean;
    private format?: ResponseType;
    private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
    private cancelToken?: CancelTokenSource;


    constructor({ format, interceptors, secure, securityWorker }: ApiConfig) {
        axios.defaults.withCredentials = true;
        this.instance = axios.create({
            baseURL: process.env.REACT_APP_API_ENDPOINT,
            // Set your desired timeout
            headers: {
                'Content-Type': 'application/json',
            },
            timeout: 10000,
        });

        interceptors?.request?.forEach((interceptor) => {
            this.instance.interceptors.request.use(interceptor.onFulfilled as any, interceptor.onRejected);
        });
        interceptors?.response?.forEach((interceptor) => {
            this.instance.interceptors.response.use(interceptor.onFulfilled, interceptor.onRejected);
        });

        this.secure = secure;
        this.format = format;
        this.securityWorker = securityWorker
    }

    // Generic method to make an HTTP request
    async request<T>({ body, format, path, query, secure, type, ...params }: FullRequestParams): Promise<AxiosResponse<T>> {

        const responseFormat = format || this.format || undefined;

        return this.instance.request({
            ...params,
            data: body,
            headers: {
                ...(params.headers || {}),
                ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {})
            },
            params: query,
            responseType: responseFormat,
            url: path
        });


    }
}
