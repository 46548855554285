import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

interface SettingsNavigationLinkProps {
    collapsed?: boolean;
    text: string;
    to: string;
}

function SettingsNavigationLink({ text, to }: SettingsNavigationLinkProps) {
    const cssClasses = classNames(
        `
        py-3 flex gap-4 items-left truncate border-b-[1px] border-ncc-grey-30 hover:font-semibold
        `
    );

    return (
        <NavLink data-testid="admin-nav-link" to={to} className={cssClasses}>
            {text}
            <div className="w-6 h-6 flex items-center ml-auto">
                <FontAwesomeIcon icon={faChevronRight} />
            </div>
        </NavLink>
    );
}

export default SettingsNavigationLink;
export type { SettingsNavigationLinkProps };
