import { AxiosResponse } from 'axios';
import { MutationOptions, useMutation, useQueryClient } from 'react-query';

import { ActivityGroup } from 'core/api/contracts/contract.types';
import { CoreController } from 'core/api/contracts/core';
import interceptors from 'core/api/interceptors';
import securityWorker from 'core/api/securityWorker';

type UpdateActivityGroupParams = {
    data: ActivityGroup;
    modelUuid;
};

function useUpdateActivityGroup<TError = unknown, TContext = unknown>(
    options?: Omit<
        MutationOptions<
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            AxiosResponse<any>,
            ReactQueryError<TError>,
            UpdateActivityGroupParams,
            TContext
        >,
        'mutationFn'
    >
) {
    const queryClient = useQueryClient();

    return useMutation(
        [],
        async ({ data, modelUuid }: UpdateActivityGroupParams) => {
            const controller = new CoreController({
                interceptors,
                securityWorker
            });

            return controller.putActivityGroup(modelUuid, data);
        },
        {
            ...options,
            onSettled: (data, error, variables, context) => {
                options?.onSettled?.(data, error, variables, context);
                queryClient.invalidateQueries({
                    queryKey: ['activity-groups']
                });
            }
        }
    );
}

export default useUpdateActivityGroup;
export type { UpdateActivityGroupParams };
