import { ReactElement, useContext, useEffect, useState } from 'react';
import { Typography } from 'components';
import { faClose } from '@fortawesome/pro-regular-svg-icons';
import dayjs from 'dayjs';

import { getMonth } from 'utils/calendar-utils';
import AdminPageContainer from '../admin-page-container';
import CalendarHeader from 'components/calendar/calendar-header';
import FontIcon from 'components/icon/icon';
import GlobalContext from 'components/calendar/context/global-context';
import Month from 'components/calendar/month';
import NotificationForm from './notification-form';
import TypographyHeader from 'components/typography/typography-header';

interface NotificationsManagmentPageProps {}

function NotificationsManagmentPage({ collapsed }): ReactElement {
    const {
        monthIndex,
        notificationUnsent,
        selectedNotification,
        setShowFormPane,
        showFormPane
    } = useContext(GlobalContext);

    const [currentMonth, setCurrentMonth] = useState(getMonth());
    const [queryParams, setQueryParams] = useState<unknown>({
        notifications_end: dayjs().endOf('day').toDate().toISOString(),
        notifications_start: dayjs().startOf('day').toDate().toISOString()
    });

    useEffect(() => {
        setCurrentMonth(getMonth(monthIndex));
    }, [monthIndex]);

    useEffect(() => {
        const notifications_start = currentMonth[0][0]
            .startOf('day')
            .toDate()
            .toISOString();

        const notifications_end = currentMonth[4][6]
            .endOf('day')
            .toDate()
            .toISOString();

        setQueryParams({
            notifications_end,
            notifications_start
        });
    }, [currentMonth]);

    const notificationFormTitle = () => {
        if (selectedNotification && notificationUnsent()) {
            return 'Edit Notification';
        } else if (selectedNotification && !notificationUnsent()) {
            return 'Sent Notification';
        } else {
            return 'New Notification';
        }
    };

    return (
        <AdminPageContainer>
            <div className="flex">
                <div className="h-screen flex flex-col grow">
                    <Typography.Header
                        level={2}
                        shadow={false}
                        className="my-6 border-b-[1px] pb-4"
                    >
                        Notifications Management
                    </Typography.Header>
                    <CalendarHeader />
                    <div className="flex flex-1">
                        <Month month={currentMonth} queryParams={queryParams} />
                    </div>
                </div>

                {showFormPane && (
                    <div className="h-full w-[450px] border-l-[1px] bg-white absolute top-0 bottom-0 right-0 shadow-[rgba(0,0,15,0.2)_-5px_0px_12px_0px] p-ncc-padding-24 z-10">
                        <TypographyHeader
                            level={2}
                            shadow={false}
                            className="border-b-[1px] pb-ncc-padding-16 mb-ncc-margin-24"
                        >
                            {notificationFormTitle()}
                            <div
                                onClick={() => setShowFormPane(false)}
                                className="cursor-pointer ml-auto"
                            >
                                <FontIcon icon={faClose}></FontIcon>
                            </div>
                        </TypographyHeader>

                        <NotificationForm />
                    </div>
                )}
            </div>
        </AdminPageContainer>
    );
}

export default NotificationsManagmentPage;
export type { NotificationsManagmentPageProps };
