/* eslint-disable @typescript-eslint/no-explicit-any */
import * as yup from 'yup';
import { ActivityGroup } from 'core/api/contracts/contract.types';
import {
    Controller,
    FormProvider,
    Path,
    useFieldArray,
    useForm
} from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ResultModal } from 'components';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { returnFormattedDayRates } from 'helpers/helpers';
import { useCallback, useEffect, useState } from 'react';
import { useModals } from '@ncc-frontend/core';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from 'components/button/button';
import ControlledInput from 'components/form/components/controlled-input';
import PageLoader from 'components/loaders/page-loader';
import Select from 'react-select';
import useCreateActivityGroup, {
    CreateActivityGroupParams
} from 'core/api/hooks/activity-groups/use-create-activity-group';
import useDeleteActivityGroup from 'core/api/hooks/activity-groups/use-delete-activity-group';
import useUpdateActivityGroup from 'core/api/hooks/activity-groups/use-update-activity-group';

type IActivityGroupsFormValues = CreateActivityGroupParams['data'];

interface IActivityGroupsFormProps {
    crudAction?: string | null;
    data?: IActivityGroupsFormValues;
    miscData?;
}

const requiredActivity = yup.string().min(1, 'An activity is required');
const requiredJobRoles = yup.object().shape({
    label: yup.string(),
    value: yup.string()
});

const schema = yup.object().shape({
    activities: yup
        .array()
        .min(1, 'Must be at least 1 activity')
        .of(requiredActivity)
        .required(),
    day_rate_uuids: yup
        .array()
        .of(requiredJobRoles)
        .nullable()
        .required('Please assign a job role to this group'),
    name: yup.string().required('Activity Group Name is required')
});

const ActivityGroupsCrudForm = ({
    crudAction,
    data,
    miscData
}: IActivityGroupsFormProps) => {
    const { pop, push } = useModals();
    const [readOnly, setReadOnly] = useState(false);
    const [apiLoading, setApiLoading] = useState(false);
    let modelUuid;

    if (data) modelUuid = data.uuid;

    function getDayRateByUuid(array, uuid) {
        const foundObject = array.find((obj) => obj.uuid === uuid);
        return {
            label: foundObject?.name,
            value: foundObject?.uuid
        };
    }

    const getDayRateObjects = useCallback(() => {
        const res = data?.day_rate_uuids?.map((uuid, index) => {
            return getDayRateByUuid(miscData, uuid);
        });

        return res;
    }, [data?.day_rate_uuids, miscData]);

    const { control, register, ...methods } =
        useForm<IActivityGroupsFormValues>({
            defaultValues: {
                activities: data ? data.activities : null,
                day_rate_uuids:
                    (miscData && crudAction === 'update') ||
                    (miscData && crudAction === 'delete')
                        ? getDayRateObjects()
                        : null,
                name: data ? data.name : ''
            },
            mode: 'onChange',
            reValidateMode: 'onChange',
            resolver: yupResolver(schema)
        });

    /**
     * Activity Field Array Config
     */

    const {
        append: activityAppend,
        fields: activityFields,
        remove: activityRemove
    } = useFieldArray<any>({
        control,
        name: 'activities'
    });

    useEffect(() => {
        if (crudAction === 'delete') setReadOnly(true);
    }, [crudAction, data, methods]);

    // Return an appropriate success message should the api response be 200 or 204

    const getSuccessMessage = (action) => {
        switch (action) {
            case 'create':
                return 'Your Activity Group was created successfully';
            case 'update':
                return 'Your Activity Group was updated successfully';
            case 'delete':
                return 'Your Activity Group was deleted successfully';
            default:
                break;
        }
        return;
    };

    // onHandleApiError Fn

    const onHandleApiError = (err) => {
        if (err.response.status !== 400) {
            pop();
            return push(ResultModal, {
                description: err.response.data.detail,
                title: 'Changes didnt happen',
                variant: 'error'
            });
        }
    };

    // onHandleApiSuccess

    const onHandleApiSuccess = () => {
        pop();
        methods.reset();
        return push(ResultModal, {
            description: getSuccessMessage(crudAction),
            title: `Action successful`,
            variant: 'success'
        });
    };

    // Create an Activity Group

    const { isLoading, mutate: createActivityGroup } = useCreateActivityGroup<{
        errors: Record<Path<IActivityGroupsFormValues> | 'invalid', string[]>;
    }>({
        onError: (err) => {
            onHandleApiError(err);
        },
        onSuccess: () => {
            onHandleApiSuccess();
        }
    });

    // Update an Activity Group

    const { mutate: updateActivityGroup } = useUpdateActivityGroup<{
        errors: Record<Path<IActivityGroupsFormValues> | 'invalid', string[]>;
    }>({
        onError: (err) => {
            onHandleApiError(err);
        },
        onSuccess: () => {
            onHandleApiSuccess();
        }
    });

    // Delete an Activity Group

    const { mutate: deleteActivityGroup } = useDeleteActivityGroup({
        onError: (err) => {
            onHandleApiError(err);
        },
        onSuccess: () => {
            onHandleApiSuccess();
        }
    });

    const formatDayRatePayload = (formData): string[] => {
        return formData.day_rate_uuids?.map((rate) => {
            return rate.value;
        });
    };

    const dataPayload = (formData): ActivityGroup => {
        return {
            activities: formData.activities,
            day_rate_uuids: formatDayRatePayload(formData),
            name: formData.name
        };
    };

    useEffect(() => {
        isLoading ? setApiLoading(true) : setApiLoading(false);
    }, [isLoading, methods.formState.isValid]);

    const formSubmitHandler = (data) => {
        switch (crudAction) {
            case 'create':
                createActivityGroup({ data: dataPayload(data) });
                break;
            case 'update':
                updateActivityGroup({
                    data: dataPayload(data),
                    modelUuid: modelUuid
                });
                break;
            case 'delete':
                deleteActivityGroup(modelUuid);
                break;
            default:
                break;
        }
    };

    return (
        <>
            {!readOnly ? (
                <FormProvider
                    register={register}
                    control={control}
                    {...methods}
                >
                    <form
                        onSubmit={methods.handleSubmit(formSubmitHandler)}
                        noValidate
                    >
                        {apiLoading ? (
                            <PageLoader
                                suppressBackground={true}
                                type="fast"
                                text={'Submitting request...'}
                            ></PageLoader>
                        ) : (
                            <div>
                                <div className="flex flex-col gap-3 text-left">
                                    <ControlledInput
                                        readOnly={readOnly}
                                        name="name"
                                        label="Activity Group Name"
                                        placeholder="Enter a name for the Activity Group..."
                                        type="text"
                                    ></ControlledInput>

                                    <div className="grid overflow-scroll max-h-[150px]">
                                        <ul>
                                            {activityFields.map(
                                                (field, index) => {
                                                    return (
                                                        <li
                                                            key={field.id}
                                                            className="flex items-center mb-ncc-margin-16"
                                                        >
                                                            <ControlledInput
                                                                label="Activity"
                                                                name={`activities.${index}`}
                                                                placeholder="Please add an activity"
                                                                readOnly={
                                                                    readOnly
                                                                }
                                                                type="text"
                                                            >
                                                                <FontAwesomeIcon
                                                                    className="p-ncc-padding-12 text-ncc-grey-50 mt-[15px] cursor-pointer"
                                                                    icon={
                                                                        faTrash
                                                                    }
                                                                    onClick={() =>
                                                                        activityRemove(
                                                                            index
                                                                        )
                                                                    }
                                                                />
                                                            </ControlledInput>
                                                        </li>
                                                    );
                                                }
                                            )}
                                        </ul>
                                        <section>
                                            <Button
                                                disabled={readOnly}
                                                variant="secondary-special"
                                                type="button"
                                                className="w-full"
                                                onClick={() => {
                                                    activityAppend(null);
                                                }}
                                            >
                                                + Add Activity
                                            </Button>
                                        </section>
                                    </div>
                                    <div>
                                        <Controller
                                            name={'day_rate_uuids'}
                                            control={control} // obtained from the useForm hook
                                            render={({ field }) => {
                                                return (
                                                    <Select
                                                        {...field}
                                                        isDisabled={readOnly}
                                                        isMulti
                                                        options={returnFormattedDayRates(
                                                            miscData
                                                        )}
                                                        placeholder="Select Job Role"
                                                    />
                                                );
                                            }}
                                        />
                                        {methods.formState.errors
                                            .day_rate_uuids && (
                                            <span className="text-utilisation-error">
                                                <FontAwesomeIcon
                                                    icon={faExclamationTriangle}
                                                    className="mr-1"
                                                />
                                                {
                                                    methods.formState.errors
                                                        .day_rate_uuids?.message
                                                }
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div className="mt-5">
                                    <div className="flex gap-2 justify-end">
                                        <Button
                                            onClick={pop}
                                            variant="tertiary"
                                        >
                                            Cancel
                                        </Button>
                                        {crudAction === 'delete' ? (
                                            <Button variant="danger">
                                                Delete
                                            </Button>
                                        ) : (
                                            <Button variant="primary">
                                                Save
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </form>
                </FormProvider>
            ) : (
                <>
                    <h1>
                        Are you sure you want to delete this Activity Group?
                    </h1>
                    <div className="mt-5">
                        <div className="flex gap-2 justify-end">
                            <Button onClick={pop} variant="tertiary">
                                Cancel
                            </Button>
                            {crudAction === 'delete' ? (
                                <Button
                                    variant="danger"
                                    onClick={() =>
                                        deleteActivityGroup(modelUuid)
                                    }
                                >
                                    Delete
                                </Button>
                            ) : (
                                <Button variant="primary">Save</Button>
                            )}
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default ActivityGroupsCrudForm;
export type { IActivityGroupsFormValues };
