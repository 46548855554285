import { DayRate } from 'core/api/contracts/contract.types';
import { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import PageLoader from 'components/loaders/page-loader';
import UserDetailsForm, { RolesType } from './user-details-form';
import useUserDetails from 'core/api/hooks/admin/use-user-details';

interface UserDetailsProps {
    contracted_days: number;
    contracted_hours: number;
    day_rate: DayRate;
    deactivated_at: string | null;
    family_name: string;
    given_name: string;
    loading?: boolean;
    roles: RolesType[] | string[];
    username: string;
}

function UserDetails(): ReactElement | null {
    const { id } = useParams();

    const {
        data: user,
        isFetching: isFetchingUser,
        isLoading: isLoadingUser
    } = useUserDetails(id as string);

    const formatStringToUppercaseFirstLetter = (str) => {
        return str[0].toUpperCase() + str.slice(1).toLowerCase();
    };

    const formatRoles = () => {
        return user?.roles?.map((r) => {
            return {
                label: formatStringToUppercaseFirstLetter(r),
                name: formatStringToUppercaseFirstLetter(r),
                value: formatStringToUppercaseFirstLetter(r)
            };
        });
    };

    if (!user) return null;

    return isFetchingUser || isLoadingUser ? (
        <div className="flex items-center justify-center h-full">
            <PageLoader
                type="fast"
                text={'Loading user details...'}
            ></PageLoader>
        </div>
    ) : (
        <UserDetailsForm
            id={id as string}
            contracted_days={user.contract?.contracted_days}
            contracted_hours={user.contract?.contracted_hours}
            contract_uuid={user.contract?.uuid as string}
            day_rate={user.day_rate as DayRate}
            deactivated_at={user.deactivated_at}
            family_name={user.family_name}
            given_name={user.given_name}
            roles={formatRoles() as unknown as RolesType[]}
            username={user.username}
            loading={isLoadingUser || isFetchingUser}
            line_manager={user.line_manager}
        ></UserDetailsForm>
    );
}

export default UserDetails;
export type { UserDetailsProps };
